import { ICredential } from "api/types/_credential";
import { IProxy } from "api/types/_proxy";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_PROXY_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postProxy, putProxy } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IProxy | null;
    listTrackingCode?: string[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const FormProxy = ({
    detail,
    triggerRefresh,
    handleClose,
    listTrackingCode = [],
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const STATUS_PROXY_OPTIONS_LANG = STATUS_PROXY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TRACKING_CODE_OPTIONS = listTrackingCode?.map((item: string) => ({ value: item, label: item }));
    const handleSubmit = async (values: any) => {
        console.log('values', values)
        try {
            setIsLoading((_prev) => true);
            const data = {
                ...(!!detail?.id ? {} : { code: values?.code }),
                protocol: values?.protocol,
                host: values?.host,
                port: Number(values?.port),
                username: values?.username,
                password: values?.password,
                tracking_codes: (values?.tracking_codes || [])?.map((item: Option) => item?.value),
                status: Number(values?.status ? values?.status?.value : STATUS_PROXY_OPTIONS_LANG[0]?.value), // 1 | 2
            };
            const response: any = detail?.id ? await putProxy(detail?.id, data) : await postProxy(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        code: !!detail?.id ? Yup.string().nullable() : Yup.string().required(`${t("This is required")}`),
        protocol: Yup.string().required(`${t("This is required")}`),
        host: Yup.string().required(`${t("This is required")}`),
        port: Yup.string().required(`${t("This is required")}`),
        username: Yup.string().required(`${t("This is required")}`),
        password: Yup.string().required(`${t("This is required")}`),
        tracking_codes: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a label")}`),
                    value: Yup.string().required(`${t("Please provide a value")}`),
                })
            )
            .required(`${t("This is required")}`)
            .min(1, `${t("At least 1 tracking code is required")}`),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            code: '',
            protocol: '',
            host: '',
            port: '',
            username: '',
            password: '',
            tracking_codes: [],
            status: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_code = valueDefault?.code;
        const v_protocol = valueDefault?.protocol;
        const v_host = valueDefault?.host;
        const v_port = valueDefault?.port;
        const v_username = valueDefault?.username;
        const v_password = valueDefault?.password;
        const v_tracking_codes = (valueDefault?.tracking_codes || [])?.map((item: string) => ({ label: item, value: item }));
        const v_status = STATUS_PROXY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.status));

        formik.setFieldValue("code", v_code);
        formik.setFieldValue("protocol", v_protocol);
        formik.setFieldValue("host", v_host);
        formik.setFieldValue("port", v_port);
        formik.setFieldValue("username", v_username);
        formik.setFieldValue("password", v_password);
        formik.setFieldValue("tracking_codes", v_tracking_codes);
        formik.setFieldValue("status", v_status);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={5} lg={5}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_PROXY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_PROXY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success", "danger"]}
                                                    />
                                                    {formik.touched.status && formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={7} lg={7}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Code")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="code"
                                                        name="code"
                                                        autocomplete={false}
                                                        value={formik?.values?.code}
                                                        disabled={!!detail?.id}
                                                        placeholder={`${t("Code")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("code", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.code && formik.errors.code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={3} lg={3}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Protocol")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="protocol"
                                                        name="protocol"
                                                        autocomplete={false}
                                                        value={formik?.values?.protocol}
                                                        placeholder={`${t("Protocol")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("protocol", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.protocol && formik.errors.protocol ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.protocol}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Host")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="host"
                                                        name="host"
                                                        autocomplete={false}
                                                        value={formik?.values?.host}
                                                        placeholder={`${t("Host")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("host", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.host && formik.errors.host ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.host}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={3} lg={3}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Port")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="port"
                                                        name="port"
                                                        autocomplete={false}
                                                        value={formik?.values?.port}
                                                        placeholder={`${t("Port")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = Number(onlyNumbers);
                                                            formik.setFieldValue("port", s)
                                                        }}
                                                    />
                                                    {formik.touched.port && formik.errors.port ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.port}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("User Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="username"
                                                        name="username"
                                                        autocomplete={false}
                                                        value={formik?.values?.username}
                                                        placeholder={`${t("User Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("username", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.username && formik.errors.username ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.username}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Password")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div className="position-relative auth-pass-inputgroup mb-2">
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            value={formik?.values?.password}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            autocomplete={false}
                                                            placeholder={`${t("Password")}...`}
                                                            onChange={(event: any) => formik.setFieldValue("password", event?.target?.value || "")}
                                                        />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Tracking Codes")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownMultiOption
                                                        name="tracking_codes"
                                                        dataList={TRACKING_CODE_OPTIONS || []}
                                                        placeholder={`${t("Tracking Codes")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.tracking_codes}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("tracking_codes", event)}
                                                    />
                                                    {formik.touched.tracking_codes && formik.errors.tracking_codes ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.tracking_codes}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Proxy") : t("Button Create Proxy")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormProxy;
