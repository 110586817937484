import { AD_PLATFORM_INVENTORY_OPTIONS, AD_TYPE_INVENTORY_LOG_OPTIONS, AD_TYPE_INVENTORY_OPTIONS, AD_TYPE_OPTIONS, BOT_OPTIONS, BROWSER_OPTIONS, DEVICE_OPTIONS, OS_OPTIONS, PLATFORM_LOG_OPTIONS } from 'helpers/constans';
import { formatLinkHTTPS, renderUrlSearchLookingUpByIP } from "helpers/format";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const TableTracking = ({ data = {} }: any) => {
    const { t, } = useTranslation();
    const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const AD_TYPE_OPTIONS_LANG = AD_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const DEVICE_OPTIONS_LANG = DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const BOT_OPTIONS_LANG = BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const BROWSER_OPTIONS_LANG = BROWSER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const OS_OPTIONS_LANG = OS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const formatDate = (strDate: string = '') => {
        return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('+')[0]}`;
    }

    return (
        <React.Fragment>
            <div className="tab-content border border-bottom-0">
                <div className="table-responsive">
                    <table className="table mb-0">
                        <tbody>
                            <tbody>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB__id`)}</th>
                                    <td><span>{data?._id}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_inventory_code`)}</th>
                                    <td><span>{data?.inventory_code}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`Tracking Code`)}</th>
                                    <td><span>{data?.tracking_code}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`Ad ID`)}</th>
                                    <td><span>{data?.ad_id}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`Sub ID`)}</th>
                                    <td><span>{data?.sub_id}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_ad_platform`)}</th>
                                    <td><span>{AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((item: any) => item?.value === data?.ad_platform)?.label || data?.ad_platform}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_ad_type`)}</th>
                                    <td><span>{AD_TYPE_OPTIONS_LANG?.find((item: any) => item?.value === data?.ad_type)?.label || data?.ad_type}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_platform`)}</th>
                                    <td><span>{PLATFORM_LOG_OPTIONS_LANG?.find((item: any) => (String(item?.value) === String(data?.platform)))?.label || data?.platform}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_banner_size`)}</th>
                                    <td><span>{data?.banner_size}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`Ad Type`)}</th>
                                    <td><span>{AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item: any) => item?.value === data?.inventory_ad_type)?.label || data?.inventory_ad_type}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_script_version`)}</th>
                                    <td><span>{data?.script_version}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_reference_link`)}</th>
                                    <td>
                                        <Link className='text-normal' to={formatLinkHTTPS(data?.reference_link)} target="_blank">
                                            {data?.reference_link}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_domain_url`)}</th>
                                    <td>
                                        <Link className='text-normal' to={formatLinkHTTPS(data?.domain_url)} target="_blank">
                                            {data?.domain_url}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_agent`)}</th>
                                    <td><span>{data?.agent}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_os`)}</th>
                                    <td><span>{OS_OPTIONS_LANG?.find((item: any) => item?.value === data?.os)?.label || data?.os}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_device`)}</th>
                                    <td><span>{DEVICE_OPTIONS_LANG?.find((item: any) => item?.value === data?.device)?.label || data?.device}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_browser`)}</th>
                                    <td><span>{BROWSER_OPTIONS_LANG?.find((item: any) => item?.value === data?.browser)?.label || data?.browser}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_ip`)}</th>
                                    <td>
                                        <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(data?.ip)} target="_blank">{data?.ip}</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_title`)}</th>
                                    <td><span>{data?.title}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_classification`)}</th>
                                    <td><span>{data?.classification}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_referrer`)}</th>
                                    <td><span>{data?.referrer}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_site_name`)}</th>
                                    <td>
                                        <span>{data?.og_site_name} </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_image`)}</th>
                                    <td>
                                        <Link className='text-normal' to={formatLinkHTTPS(data?.og_image)} target="_blank">
                                            {data?.og_image}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_type`)}</th>
                                    <td><span>{data?.og_type}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_url`)}</th>
                                    <td>
                                        <Link className='text-normal' to={formatLinkHTTPS(data?.og_url)} target="_blank">
                                            {data?.og_url}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_title`)}</th>
                                    <td><span>{data?.og_title}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_description`)}</th>
                                    <td><span>{data?.og_description}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_og_article_author`)}</th>
                                    <td><span>{data?.og_article_author}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_dable_item_id`)}</th>
                                    <td><span>{data?.dable_item_id}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_dable_author`)}</th>
                                    <td><span>{data?.dable_author}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_article_section`)}</th>
                                    <td><span>{data?.article_section}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_article_published_time`)}</th>
                                    <td><span>{formatDate(data?.article_published_time)}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_domain`)}</th>
                                    <td>
                                        <Link className='text-normal' to={formatLinkHTTPS(data?.domain)} target="_blank">
                                            {data?.domain}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_path`)}</th>
                                    <td><span>{data?.path}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_bot`)}</th>
                                    <td><span>{(BOT_OPTIONS_LANG?.filter((item) => (String(item?.value) === String(data?.bot)))[0]?.label || data?.bot)}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_news_keywords`)}</th>
                                    <td><span>{data?.news_keywords}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_uuid`)}</th>
                                    <td><span>{data?.uuid}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_news_id`)}</th>
                                    <td><span>{data?.news_id}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_created_at`)}</th>
                                    <td><span>{`${String(data?.created_at).split('T')[0]} ${String(String(data?.created_at).split('T')[1]).split('.')[0]}`}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: '150px', padding: '5px' }}>{t(`WEB_updated_at`)}</th>
                                    <td><span>{`${String(data?.updated_at).split('T')[0]} ${String(String(data?.updated_at).split('T')[1]).split('.')[0]}`}</span></td>
                                </tr></tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment >
    );
};

export default TableTracking;
