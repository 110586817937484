import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatHour, formatNumberWithCommas, formatNumberWithoutCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, reportHourly as onGetReportHourly } from "../../../store/thunks";
import ChartLine from "./ChartLine";

registerLocale('en', en);
registerLocale('ko', ko);

const ICON_COMPARISON: any = {
    up: '▲',
    down: '▼',
};

const CLASS_COMPARISON: any = {
    up: 'fs-11 text-danger',
    down: 'fs-11 text-primary',
};

const HourlyStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        domain: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        comparison_start_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`),
        comparison_end_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            hourly: state.hourly,
            isHourlySuccess: state.isHourlySuccess,
            isHourlyLoading: state.isHourlyLoading,
            error: state.error,
        })
    );

    const { hourly, isHourlyLoading } = useSelector(ManagementProperties);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [dateComparisonSearch, setDateComparisonSearch] = useState<any[]>([query?.comparison_start_date ? moment(query?.comparison_start_date, 'Y-MM-DD').toDate() : null, query?.comparison_end_date ? moment(query?.comparison_end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDateComparison, endDateComparison] = dateComparisonSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportHourly(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            comparison_start_date: startDateComparison ? moment(new Date(startDateComparison)).format("Y-MM-DD") : '',
            comparison_end_date: endDateComparison ? moment(new Date(endDateComparison)).format("Y-MM-DD") : '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            comparison_start_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`,
            comparison_end_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`,
            domain: '',
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setDateComparisonSearch([moment(queryNew?.comparison_start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.comparison_end_date || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
    };

    const calculateStats = useMemo(() => {
        let data = hourly?.list || [];
        let stats: any = {
            impression_min: Number.MAX_SAFE_INTEGER,
            impression_max: Number.MIN_SAFE_INTEGER,
            click_min: Number.MAX_SAFE_INTEGER,
            click_max: Number.MIN_SAFE_INTEGER,
            ctr_min: Number.MAX_SAFE_INTEGER,
            ctr_max: Number.MIN_SAFE_INTEGER,
        };

        data.forEach((item: any) => {
            // Update impressions
            const impression = formatNumberWithoutCommas(item.impression);
            if (impression < stats.impression_min) stats.impression_min = impression;
            if (impression > stats.impression_max) stats.impression_max = impression;

            // Update clicks
            const click = formatNumberWithoutCommas(item.click);
            if (click < stats.click_min) stats.click_min = click;
            if (click > stats.click_max) stats.click_max = click;

            // Update CTR
            const ctr = parseFloat(item.ctr);
            if (ctr < parseFloat(stats.ctr_min)) stats.ctr_min = ctr.toFixed(2);
            if (ctr > parseFloat(stats.ctr_max)) stats.ctr_max = ctr.toFixed(2);
        });

        // Convert numbers back to string with necessary formatting
        stats.impression_min = formatNumberWithCommas(stats.impression_min).toString();
        stats.impression_max = formatNumberWithCommas(stats.impression_max).toString();
        stats.click_min = formatNumberWithCommas(stats.click_min).toString();
        stats.click_max = formatNumberWithCommas(stats.click_max).toString();
        stats.ctr_min = formatNumberWithCommas(stats.ctr_min).toString();
        stats.ctr_max = formatNumberWithCommas(stats.ctr_max).toString();
        return stats;
    }, [hourly?.list]);

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Time'),
                accessor: "hour",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '120px' }}>
                                <span >{formatHour(cell?.value)}</span>
                            </div>

                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Impression')}
                        <br />
                        <span className="fw-normal">({t('Comparison')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const classN = !!item?.text_row ? 'text-end' : `text-end ${calculateStats?.impression_min === cell?.value ? 'td-bg-primary' : (calculateStats?.impression_max === cell?.value ? 'td-bg-danger' : '')}`;
                    return (
                        <>
                            <div className={classN} style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[item?.impression_compare] || ''}>{ICON_COMPARISON[item?.impression_compare] || ''} <span className="fs-13">{formatNumberWithCommas(item?.value_impression_change)}</span></span>)</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Click')}
                        <br />
                        <span className="fw-normal">({t('Comparison')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const classN = !!item?.text_row ? 'text-end' : `text-end ${calculateStats?.click_min === cell?.value ? 'td-bg-primary' : (calculateStats?.click_max === cell?.value ? 'td-bg-danger' : '')}`;
                    return (
                        <>
                            <div className={classN} style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[item?.click_compare] || ''}>{ICON_COMPARISON[item?.click_compare] || ''} <span className="fs-13">{formatNumberWithCommas(item?.value_click_change)}</span></span>)</div>}
                        </>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('CTR')}
                        <br />
                        <span className="fw-normal">({t('Comparison')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const classN = !!item?.text_row ? 'text-end' : `text-end ${calculateStats?.ctr_min === cell?.value ? 'td-bg-primary' : (calculateStats?.ctr_max === cell?.value ? 'td-bg-danger' : '')}`;
                    return (
                        <>
                            <div className={classN} style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[item?.ctr_compare] || ''}>{ICON_COMPARISON[item?.ctr_compare] || ''} <span className="fs-13">{formatNumberWithCommas(item?.value_ctr_change)} %</span></span>)</div>}
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, query?.domain, JSON.stringify(calculateStats)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    const handleChangePickerComparison = (values: any[] = []) => {
        setDateComparisonSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => ({
                    label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
                    value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
                }));

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        document.title = `${t("Hourly Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return hourly?.list?.map((item: any) => formatHour(item?.hour)) || [];
    }, [hourly?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = hourly?.list?.reduce((ob: any, item: any) => {
            ob.impression?.push(Number(String(item?.impression).replace(/,/g, '')));
            ob.click?.push(Number(String(item?.click).replace(/,/g, '')));
            return ob;
        }, { impression: [], click: [] }) || [];
        return (
            [
                {
                    name: t('Click'),
                    type: 'line',
                    data: obChart?.click,
                },
                {
                    name: t('Impression'),
                    type: 'column',
                    data: obChart?.impression,
                },
            ]
        )
    }, [hourly?.list, i18n?.language]);



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Hourly Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={hourly?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={4} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Comparison Date')} isShow={!!startDateComparison || !!endDateComparison}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDateComparison || null}
                                                        endDate={endDateComparison || null}
                                                        onChangePicker={handleChangePickerComparison}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isHourlyLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ChartLine
                                        className="my-3"
                                        titles={[t('Click'), t('Impression')]}
                                        categories={categories}
                                        series={series}
                                    />
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={hourly?.list?.length ? (
                                                [
                                                    {
                                                        ...hourly?.total_data,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...hourly?.list]
                                            ) : []}
                                            customPageSize={Number(hourly?.pagination?.total)}
                                            customPageIndex={1}
                                            totalRecords={hourly?.pagination?.total}
                                            customPageCount={Math.ceil(Number(hourly?.pagination?.total) / Number(hourly?.pagination?.total))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isHourlyLoading}
                                            isShowPagination={false}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HourlyStatistic;
