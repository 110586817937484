import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { blockAdsMultiplesUUID, blockMultiplesUUID, changeStatusBlockUUID, deleteBlockAdsMultiplesUUID, deleteBlockMultiplesUUID, getAllMedias, uuidStatistics as onGetUUIDStatistics } from "../../../store/thunks";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/allRoutes";
import DropdownOption from "components/Common/DropdownOption";
import { STATUS_BLOCK_UUID_OPTIONS, STATUS_EXCEPT_UUID_OPTIONS } from "helpers/constans";
import InputsRange from "components/Common/InputsRange";
import { IMedia } from "api/types/_media";
import SVGImageDefault from "components/Images/SVGImageDefault";
import DropdownMedia from "components/Common/DropdownMedia";


const SORT_BY_DEFAULT = 'request';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const UUIDStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const { setDurationResponses } = useDurationResponses();

    const STATUS_BLOCK_OPTIONS_LANG = STATUS_BLOCK_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_EXCEPT_OPTIONS_LANG = STATUS_EXCEPT_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD")),
        uuid: withDefault(StringParam, ''),
        is_block: withDefault(StringParam, ''),
        is_except: withDefault(StringParam, ''),
        start_request: withDefault(StringParam, ''),
        end_request: withDefault(StringParam, ''),
        start_impression: withDefault(StringParam, ''),
        end_impression: withDefault(StringParam, ''),
        start_click: withDefault(StringParam, ''),
        end_click: withDefault(StringParam, ''),
        start_close: withDefault(StringParam, ''),
        end_close: withDefault(StringParam, ''),
        domain: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            uuidStatistics: state.uuidStatistics,
            isUUIDStatisticSuccess: state.isUUIDStatisticSuccess,
            isUUIDStatisticLoading: state.isUUIDStatisticLoading,
            error: state.error,
        })
    );

    const { uuidStatistics, isUUIDStatisticLoading } = useSelector(ManagementProperties);

    const [uuidSearch, setUUIDSearch] = useState<string>(query?.uuid || "");

    const [blockSearch, setBlockSearch] = useState<Option | null>(STATUS_BLOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_block))[0]);

    const [exceptSearch, setExceptSearch] = useState<Option | null>(STATUS_EXCEPT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_except))[0]);

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;

    // Request from to range
    const [startRequestSearch, setStartRequestSearch] = useState<string>(query?.start_request || "");
    const [endRequestSearch, setEndRequestSearch] = useState<string>(query?.end_request || "");

    // Impression from to range
    const [startImpressionSearch, setStartImpressionSearch] = useState<string>(query?.start_impression || "");
    const [endImpressionSearch, setEndImpressionSearch] = useState<string>(query?.end_impression || "");

    // Click from to range
    const [startClickSearch, setStartClickSearch] = useState<string>(query?.start_click || "");
    const [endClickSearch, setEndClickSearch] = useState<string>(query?.end_click || "");

    // Close from to range
    const [startCloseSearch, setStartCloseSearch] = useState<string>(query?.start_close || "");
    const [endCloseSearch, setEndCloseSearch] = useState<string>(query?.end_close || "");

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetUUIDStatistics(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            uuid: uuidSearch || '',
            is_block: blockSearch?.value || '',
            is_except: exceptSearch?.value || '',
            start_request: startRequestSearch || '',
            end_request: endRequestSearch || '',
            start_impression: startImpressionSearch || '',
            end_impression: endImpressionSearch || '',
            start_click: startClickSearch || '',
            end_click: endClickSearch || '',
            start_close: startCloseSearch || '',
            end_close: endCloseSearch || '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD"),
            uuid: '',
            is_block: '',
            is_except: '',
            start_request: '',
            end_request: '',
            start_impression: '',
            end_impression: '',
            start_click: '',
            end_click: '',
            start_close: '',
            end_close: '',
            domain: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setUUIDSearch('');
        setBlockSearch({ label: t('All Status Block'), value: '' });
        setExceptSearch({ label: t('All Status Block'), value: '' });
        setStartRequestSearch("");
        setEndRequestSearch("");
        setStartImpressionSearch("");
        setEndImpressionSearch("");
        setStartClickSearch("");
        setEndClickSearch("");
        setStartCloseSearch("");
        setEndCloseSearch("");
        setMediaSearch({ label: t('All Media'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setOptionsSelected((prev: any) => []);
    };

    // Begin:: update status Block IP
    const [itemUpdateBlock, setItemUpdateBlock] = useState<{ uuid: string, data: { block: number, type: 'EXCEPT' | 'BLOCK' } } | null>(null);
    const [isLoadingUpdateBlock, setIsLoadingUpdateBlock] = useState<boolean>(false);
    const handleShowConfirmUpdateBlock = (item: { uuid: string, data: { block: number, type: 'EXCEPT' | 'BLOCK' } } | null) => {
        setItemUpdateBlock(item);
    };
    const handleOnConfirmUpdateBlock = async () => {
        try {
            if (!itemUpdateBlock) {
                return;
            }
            setIsLoadingUpdateBlock((_prev) => true);
            const dataForm = { uuids: [itemUpdateBlock?.uuid] };
            let response: any = null;
            if (itemUpdateBlock?.data?.type === 'EXCEPT') {
                response = itemUpdateBlock?.data?.block ? await blockAdsMultiplesUUID({ ...dataForm, type: "uuid_list" }) : await deleteBlockAdsMultiplesUUID(dataForm);
            } else {
                response = itemUpdateBlock?.data?.block ? await blockMultiplesUUID({ ...dataForm, type: "uuid_list" }) : await deleteBlockMultiplesUUID(dataForm);
            }
            setIsLoadingUpdateBlock((_prev) => false);
            if (response?.data) {
                setItemUpdateBlock((_prev) => null);
                const newState: any = (uuidStatistics?.list || [])?.map((item: any) => {
                    if (item?.uuid === itemUpdateBlock?.uuid) {
                        return ({
                            ...item, ...(
                                itemUpdateBlock?.data?.type === 'EXCEPT' ? { is_except: itemUpdateBlock?.data?.block } : { is_block: itemUpdateBlock?.data?.block }
                            )
                        });
                    }
                    return item;
                });
                dispatch(changeStatusBlockUUID({
                    ...uuidStatistics,
                    list: newState
                }));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateBlock((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update status Block IP

    // Begin:: handle select table 
    const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
        const index = String(valueIndex);
        if (valueCheck?.value === 'ALL') {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (e.target.checked) {
                    if (prevClone[index] === undefined) {
                        prevClone[index] = [];
                    }
                    prevClone[index] = (uuidStatistics?.list || [])?.map((item: any) => ({ value: item?.uuid, label: item?.uuid }));
                    return prevClone;
                } else {
                    prevClone[index] = [];
                    return prevClone;
                }
            });
            return;
        }
        if (!e.target.checked) {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
                return prevClone;
            });
        } else {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = ([...prevClone[index], valueCheck]);
                return prevClone;
            });
        }
    };

    const isChecked = (valueCheck: Option) => {
        const index = String(query.page);
        return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
    };

    const isCheckedAll = (valueIndex: string) => {
        const valueIndexString = String(valueIndex);
        return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === uuidStatistics?.list?.length);
    };

    const countRowSelected = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
            return Number(total + (value || [])?.length);
        }, 0)
    }, [optionsSelected]);

    const listKeyword = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
            return ([...arr, ...(value || [])]);
        }, [])
    }, [optionsSelected]);

    // End:: handle select table 

    // Begin::Delete
    const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
    const [typeAction, setTypeAction] = useState<string>('');
    const handleConfirmBlock = (type_action: string = '') => {
        setTypeAction((_prev) => type_action);
        setIsConfirm((_prev) => true);
    };

    const handleActionBlock = async () => {
        if (optionsSelected?.length === 0) {
            return;
        }
        try {
            setIsConfirmLoading((_prev) => true);
            const uuids = (optionsSelected || []).flat()?.map((item: any) => item?.value);
            const dataForm = {
                uuids: uuids,
                type: "uuid_list"
            };
            const response: any = typeAction === 'EXCEPT' ? await blockAdsMultiplesUUID(dataForm) : await blockMultiplesUUID(dataForm);
            if (response?.code === 200) {
                dispatch(onGetUUIDStatistics(query));
                setIsConfirmLoading((_prev) => false);
                setIsConfirm((_prev) => false);
                setOptionsSelected((prev: any) => []);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIsConfirm((_prev) => false);
    };
    // End::Delete

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Key'),
                accessor: "",
                filterable: true,
                sortable: false,
                thClass: 'text-start',
                thWidth: 46,
                thComponent: () => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
                            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
                        </div>
                    </>
                ),
                Cell: (cell: any) => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.uuid, label: cell?.row?.original?.uuid })} value={cell?.row?.original?.uuid ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.uuid, label: cell?.row?.original?.uuid }), query?.page, e)} id={`cell-check-${cell?.row?.original?.uuid ?? ''}`} />
                            <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.uuid ?? ''}`}></label>
                        </div>
                    </>
                ),
            },
            {
                Header: t('Ranking'),
                accessor: "rank_by_request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            {!item?.text_row && <div className="text-center pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "domain",
                filterable: true,
                sortable: false,
                thWidth: 220,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0] || null;
                    return (<>
                        {item?.text_row ? <strong>{item?.text_row}</strong> :
                            (<div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                                <div className="me-2">
                                    {media?.logo_url ? (
                                        <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                        <SVGImageDefault style={{ height: '48px' }} />
                                    )}
                                </div>
                                <div>
                                    <div>
                                        {media?.name || ''}
                                    </div>
                                    <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website || cell?.value)} target="_blank">
                                        {media?.website || cell?.value}
                                    </Link>
                                </div>
                            </div>)}
                    </>)
                },
            },
            {
                Header: t('UUID'),
                accessor: "uuid",
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-center pe-4',
                thWidth: 340,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '320px' }}>
                                <CopyWrapper contentCopy={cell?.value}>
                                    <Link className='text-normal' to={{ pathname: `${ROUTES.ADS_LOG}`, search: `?uuid=${cell?.value}&start_date=${query?.start_date}&end_date=${query?.end_date}` }} target="_blank">
                                        {cell?.value}
                                    </Link>
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Except On/Off'),
                accessor: "is_except",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                // description: t('on/off to alarm message'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }}>
                            <div className="form-check form-switch form-switch-sm form-switch-danger text-center" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_except" id="customSwitchsizelg" checked={Boolean(item?.is_except)}
                                    onChange={(event: any) => {
                                        handleShowConfirmUpdateBlock({ uuid: item?.uuid, data: { block: Number(event.target.checked), type: 'EXCEPT' }, });
                                    }}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t('Block On/Off'),
                accessor: "is_block",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                // description: t('on/off to alarm message'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }}>
                            <div className="form-check form-switch form-switch-sm form-switch-danger text-center" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_block" id="customSwitchsizelg" checked={Boolean(item?.is_block)}
                                    onChange={(event: any) => {
                                        handleShowConfirmUpdateBlock({ uuid: item?.uuid, data: { block: Number(event.target.checked), type: 'BLOCK' }, });
                                    }}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t('Request'),
                accessor: "request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Close'),
                accessor: "close",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: 'abc',
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-end',
                thWidth: 50,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            }
        ],
        [i18n?.language, JSON.stringify(optionsSelected), query?.page, query?.start_date, query?.end_date, JSON.stringify(uuidStatistics?.list), JSON.stringify(listMedia), userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => {
                    const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
                    return ({
                        label: item?.name || t('All Media'),
                        value: `${item?.id || ''}`,
                    })
                });
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }

        document.title = `${t("UUID Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    useEffect(() => {
        setDurationResponses([
            {
                name: 'UUID Statistics',
                time: uuidStatistics?.pagination?.duration || 0
            }
        ]);
    }, [uuidStatistics?.pagination?.duration]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('UUID Statistics')} pageTitle={t('Statistics')} />
                    <Row>
                        <Col sm={12} md={12}>
                            <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 1 hour')}</div>
                                <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={uuidStatistics?.pagination?.total || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-3">
                                    <Row className="g-4 align-items-center mt-0">
                                        <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                            <InputsRange
                                                nameFrom="from"
                                                nameTo="to"
                                                valueFrom={startRequestSearch}
                                                valueTo={endRequestSearch}
                                                placeholderFrom={`${t('Request (Start)')}...`}
                                                placeholderTo={`${t('Request (End)')}...`}
                                                labelFrom={`${t('Request (Start)')}`}
                                                labelTo={`${t('Request (End)')}`}
                                                isTypeNumber={true}
                                                onChangeFrom={(val) => setStartRequestSearch(val)}
                                                onChangeTo={(val) => setEndRequestSearch(val)}
                                                onKeyDownFrom={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                                onKeyDownTo={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                            <InputsRange
                                                nameFrom="from"
                                                nameTo="to"
                                                valueFrom={startImpressionSearch}
                                                valueTo={endImpressionSearch}
                                                placeholderFrom={`${t('Impression (Start)')}...`}
                                                placeholderTo={`${t('Impression (End)')}...`}
                                                labelFrom={`${t('Impression (Start)')}`}
                                                labelTo={`${t('Impression (End)')}`}
                                                isTypeNumber={true}
                                                onChangeFrom={(val) => setStartImpressionSearch(val)}
                                                onChangeTo={(val) => setEndImpressionSearch(val)}
                                                onKeyDownFrom={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                                onKeyDownTo={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 order-0 order-xl-1' >
                                            <InputsRange
                                                nameFrom="from"
                                                nameTo="to"
                                                valueFrom={startClickSearch}
                                                valueTo={endClickSearch}
                                                placeholderFrom={`${t('Click (Start)')}...`}
                                                placeholderTo={`${t('Click (End)')}...`}
                                                labelFrom={`${t('Click (Start)')}`}
                                                labelTo={`${t('Click (End)')}`}
                                                isTypeNumber={true}
                                                onChangeFrom={(val) => setStartClickSearch(val)}
                                                onChangeTo={(val) => setEndClickSearch(val)}
                                                onKeyDownFrom={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                                onKeyDownTo={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 order-0 order-xl-1'>
                                            <InputsRange
                                                nameFrom="from"
                                                nameTo="to"
                                                valueFrom={startCloseSearch}
                                                valueTo={endCloseSearch}
                                                placeholderFrom={`${t('Close (Start)')}...`}
                                                placeholderTo={`${t('Close (End)')}...`}
                                                labelFrom={`${t('Close (Start)')}`}
                                                labelTo={`${t('Close (End)')}`}
                                                isTypeNumber={true}
                                                onChangeFrom={(val) => setStartCloseSearch(val)}
                                                onChangeTo={(val) => setEndCloseSearch(val)}
                                                onKeyDownFrom={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                                onKeyDownTo={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 order-0 order-xl-2' >
                                            <LabelWrapper label={t('UUID')} isShow={!!uuidSearch}>
                                                <Input
                                                    type="text"
                                                    className="form-control search"
                                                    placeholder={`${t('UUID')}...`}
                                                    value={uuidSearch}
                                                    onChange={(event: any) => {
                                                        const input = event?.target?.value;
                                                        setUUIDSearch(input)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={2} xl={2} xxl={2} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                            <LabelWrapper label={t('Except On/Off')} isShow={true}>
                                                <DropdownOption
                                                    name="type"
                                                    dataList={STATUS_EXCEPT_OPTIONS_LANG || []}
                                                    placeholder={`${t("Except On/Off")}...`}
                                                    className="dropdown-status-rounded"
                                                    classNamePrefix="name-prefix"
                                                    initialValue={exceptSearch || null}
                                                    onChangeSelect={(e: any) => setExceptSearch(e)}
                                                    isHasOptionAll={true}
                                                    optionAll={{ label: t('All Status Block'), value: '' }}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={2} xl={2} xxl={2} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                            <LabelWrapper label={t('Block On/Off')} isShow={true}>
                                                <DropdownOption
                                                    name="type"
                                                    dataList={STATUS_BLOCK_OPTIONS_LANG || []}
                                                    placeholder={`${t("Block On/Off")}...`}
                                                    className="dropdown-status-rounded"
                                                    classNamePrefix="name-prefix"
                                                    initialValue={blockSearch || null}
                                                    onChangeSelect={(e: any) => setBlockSearch(e)}
                                                    isHasOptionAll={true}
                                                    optionAll={{ label: t('All Status Block'), value: '' }}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={4} xl={2} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-2 mb-0 mb-md-2'>
                                            <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                <DatePickerCustom
                                                    placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                    startDate={startDate || null}
                                                    endDate={endDate || null}
                                                    onChangePicker={handleChangePicker}
                                                    showOptions={[
                                                        'today', 'yesterday', 'two_day_ago',
                                                        'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                        'this_week', 'last_week',
                                                        'this_month', 'last_month',
                                                    ]}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 order-xl-2'>
                                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                <DropdownMedia
                                                    name="medias"
                                                    dataList={listMedia}
                                                    initialValue={mediaSearch}
                                                    onChangeSelect={(event: any) => {
                                                        setMediaSearch((_prev) => event);
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={12} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-md-end mt-3 mt-md-2 mb-0 mb-md-2 order-0 order-xl-2">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-2 "
                                                    onClick={searchData}
                                                    disabled={isUUIDStatisticLoading}
                                                >
                                                    <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                    {t('Button Search')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary fs-14"
                                                    onClick={resetData}
                                                >
                                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                    {t('Button Reset')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="w-100 my-2 mx-3 text-start">
                                    <button
                                        type="button"
                                        className="btn btn-danger fs-14 me-4"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleConfirmBlock('EXCEPT');
                                        }}
                                        disabled={countRowSelected <= 0}
                                    >
                                        {countRowSelected > 0 && (
                                            <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                                style={{ transform: 'translate(0%, -70%)' }}
                                            >
                                                {formatNumberWithCommas(countRowSelected)}
                                                <span className="visually-hidden">total keywords selected</span>
                                            </span>)}
                                        {t('Button Add Except UUID')}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger fs-14"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleConfirmBlock('BLOCK');
                                        }}
                                        disabled={countRowSelected <= 0}
                                    >
                                        {countRowSelected > 0 && (
                                            <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                                style={{ transform: 'translate(0%, -70%)' }}
                                            >
                                                {formatNumberWithCommas(countRowSelected)}
                                                <span className="visually-hidden">total keywords selected</span>
                                            </span>)}
                                        {t('Button Add Block UUID')}
                                    </button>
                                </div>
                                <CardBody className="pt-3">
                                    <TableContainer
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="align-middle table-bordered-dashed " // table-have-first-row-is-total
                                        theadClass="table-light text-muted"
                                        columns={columns}
                                        data={uuidStatistics?.list?.length ? (
                                            [
                                                // {
                                                //     ...uuidStatistics?.total,
                                                //     domain: null,
                                                //     text_row: t('Sum')
                                                // },
                                                ...uuidStatistics?.list]
                                        ) : []}
                                        customPageSize={query.limit}
                                        customPageIndex={query.page - 1}
                                        totalRecords={uuidStatistics?.pagination?.total}
                                        customPageCount={Math.ceil(Number(uuidStatistics?.pagination?.total) / Number(uuidStatistics?.pagination?.limit))}
                                        handleChangePage={handleChangePage}
                                        manualSorting={true}
                                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                        handleChangeSorting={handleChangeSorting}
                                        isLoading={isUUIDStatisticLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
                <ModalConfirm
                    textButtonConfirm={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Button Except') : t('Button Block')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Button Release') : t('Button Unblock'))}
                    classIconButtonConfirm=''
                    classButtonConfirm={'btn-danger'}
                    header={itemUpdateBlock?.data?.block ? t('Confirm to ON') : t('Confirm to OFF')}
                    title={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Are you sure you want to except this?') : t('Are you sure you want to block this?')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Are you sure you want to release this?') : t('Are you sure you want to unblock this?'))}
                    content={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('After confirming the except action, the data will be excepted. Do you want to proceed with the except action.') : t('After confirming the block action, the data will be blocked. Do you want to proceed with the block action.')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('After confirming the release action, the data will be released. Do you want to proceed with the release action.') : t('After confirming the unblock action, the data will be unblocked. Do you want to proceed with the unblock action.'))}
                    isShowIcon={false}
                    isOpen={!!itemUpdateBlock}
                    isLoading={isLoadingUpdateBlock}
                    onClose={() => setItemUpdateBlock((prev: any) => null)}
                    onConfirm={handleOnConfirmUpdateBlock}
                />
                <ModalConfirm
                    header={typeAction === 'EXCEPT' ? t("Button Except UUID") : t("Button Block UUID")}
                    classIconButtonConfirm=''
                    textButtonConfirm={typeAction === 'EXCEPT' ? t('Button Except UUID') : t('Button Block UUID')}
                    title={typeAction === 'EXCEPT' ? t('Are you sure you want to except this?') : t('Are you sure you want to block this?')}
                    content={typeAction === 'EXCEPT' ? t('After confirming the except action, the data will be excepted. Do you want to proceed with the except action.') : t('After confirming the block action, the data will be blocked. Do you want to proceed with the block action.')}
                    isShowIcon={false}
                    isOpen={isOpenConfirm}
                    isLoading={isConfirmLoading}
                    onClose={onCloseConfirmClick}
                    onConfirm={handleActionBlock}
                />
            </div>
        </React.Fragment>
    );
};

export default UUIDStatistic;