import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IIP, IUUID } from "./types/_statistic";
const api = new APIClient();

const path = '/';
const statisticApi = {
  ipStatistics(params: any):Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-statistics`
    return api.get(url, params)
  },
  uuidStatistics(params: any):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-statistics`
    return api.get(url, params)
  },
  uuidBlocks(params: any):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.get(url, params)
  },
  uuidBlocksAds(params: any):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.get(url, params)
  },
  postLookupIP(params: any = {}):Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}utilities/ip/lookup`
    return api.post(url, params)
  },
  putStatusBlockIP(ip: string | number, dataForm: any = {}):Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-statistics/${ip}/block`
    return api.post(url, dataForm)
  },
  postBlockMultiplesUUID(dataForm: any = {}):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.post(url, dataForm)
  },
  deleteBlockMultiplesUUID(dataForm: any = {}):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.delete(url, {data: dataForm})
  },
  postBlockAdsMultiplesUUID(dataForm: any = {}):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.post(url, dataForm)
  },
  deleteBlockAdsMultiplesUUID(dataForm: any = {}):Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.delete(url, {data: dataForm})
  },
}
export default statisticApi
