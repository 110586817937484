const ROLES_FOR_APP = {    
    DASHBOARD_VIEW: "view",

    ROLE_LIST: "role_list",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    USER_LIST: "user_list",
    USER_CREATE: "user_create",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_remove",

    ADMIN_ACTIVITY_LOGS: 'user_list', // 

    MANAGEMENT_PROXY: 'managements_proxy', //
    PROXY_CREATE: "proxy_create",
    PROXY_UPDATE: "proxy_update",
    PROXY_DELETE: "proxy_remove",

    MANAGEMENT_MEDIA: 'managements_media',
    MEDIA_CREATE: "media_create",
    MEDIA_UPDATE: "media_update",
    MEDIA_DELETE: "media_remove",
    MEDIA_CONFIG_CPM: "media_update",
    MEDIA_CONFIG_ACCOUNT_LOGIN: "media_update",


    MANAGEMENT_INVENTORY: 'managements_inventory',
    INVENTORY_CREATE: "inventory_create",
    INVENTORY_UPDATE: "inventory_update",
    INVENTORY_DELETE: "inventory_remove",

    MANAGEMENT_AGENCY: 'managements_agency',
    AGENCY_CREATE: "agency_create",
    AGENCY_UPDATE: "agency_update",
    AGENCY_DELETE: "agency_remove",
    AGENCY_CONFIG_CPM: "agency_update",
    AGENCY_CONFIG_ACCOUNT_LOGIN: "agency_update",

    MANAGEMENT_API: 'managements_api',
    API_CREATE: "api_create",
    API_UPDATE: "api_update",
    API_DELETE: "api_remove",

    MONITOR_USER_CREATE: "media_create",
    MONITOR_USER_UPDATE: "media_update",
    MONITOR_USER_DELETE: "media_remove",

    REPORT_MEDIA: 'reports_media',
    REPORT_MEDIA_V2: 'reports_media_v2',
    REPORT_INVENTORY: 'reports_inventory',
    REPORT_AD_PLATFORM: 'reports_ad_platform',
    REPORT_AGENCY: 'reports_agency',
    REPORT_REVENUE: 'reports_revenue',
    REPORT_COMMISSION: 'reports_commission',
    REPORT_SETTLEMENT: 'reports_settlement',

    ADS_LOG: 'ads_log',

    MONITOR_SCRIPT: 'monitor_script',
    MONITOR_SCRIPT_ALARM: 'monitor_script',
    MONITOR_SCRIPT_WEBSITE: 'monitor_script',

    MONITOR_USER: 'monitor_script',
    MONITOR_CONFIGURATION: 'monitor_configuration',
    IP_STATISTICS: 'ip_statistics',
    UUID_STATISTICS: 'uuid_statistics',
    UUID_BLOCKS: 'uuid_block',
    UUID_EXCEPT: 'uuid_except',
    DAILY_STATISTICS: 'daily_statistics',
    HOURLY_STATISTICS: 'hourly_statistics',
};

const isHavePermissionRole = (keyRoles: string | string[], permissions: {id: string | number, name: string}[] = []):boolean => {
    const roles =  typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return  true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };