import { IInventory } from "api/types/_inventory";
import { IMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import TooltipCustom from "components/Common/TooltipCustom";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    AD_PLATFORM_INVENTORY_OPTIONS,
    AD_TYPE_INVENTORY_OPTIONS,
    FILL_RATE_TIME_OPTIONS,
    IMPRESSION_INVENTORY_OPTIONS,
    POSITION_INVENTORY_OPTIONS,
    STATUS_INVENTORY_OPTIONS,
    TYPE_ADS_DEVICE_OPTIONS,
} from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import { postInventory, putInventory } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IInventory | null;
    medias?: IMedia[];
    inventories?: IInventory[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormInventory = ({
    detail,
    medias = [],
    inventories = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const MEDIA_OPTIONS_LANG = medias?.reduce(
        (arr: any, item: any) =>
            item?.code
                ? [...arr, { value: String(item?.code), label: item?.name }]
                : arr,
        []
    );

    const INVENTORY_OPTIONS_LANG = inventories?.reduce(
        (arr: any, item: any) => {
            if ((item?.parent_code === null) || (item?.parent_code === detail?.parent_code)) {
                return [...arr, { value: String(item?.code), label: `${item?.media?.name} (${item?.width}*${item?.height})` }];
            }
            return arr;
        },
        [{ value: '', label: t('None') }]
    );

    const STATUS_INVENTORY_OPTIONS_LANG = STATUS_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const IMPRESSION_INVENTORY_OPTIONS_LANG = IMPRESSION_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const FILL_RATE_TIME_OPTIONS_LANG = FILL_RATE_TIME_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );


    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                media_code: Number(values?.media_code?.value),
                parent_code: !!values?.parent_code?.value ? Number(values?.parent_code?.value) : null, // SUB INVENTORY
                ad_platform: values?.ad_platform ? Number(values?.ad_platform?.value) : AD_PLATFORM_INVENTORY_OPTIONS_LANG[0]?.value, // 1, 2 SUB INVENTORY
                ad_type: values?.ad_type ? Number(values?.ad_type?.value) : AD_TYPE_INVENTORY_OPTIONS_LANG[0]?.value, // SUB INVENTORY
                ad_id: values?.ad_id,
                ad_tracking_code: values?.ad_tracking_code || '',
                ad_sub_id: values?.ad_sub_id || '',
                configs: {
                    flg_click: Number(values?.flg_click),
                    flg_banner_size: Number(values?.flg_banner_size),
                    fill_rate: Number(values?.fill_rate),
                    is_block_referral: Number(values?.is_block_referral),
                    is_except_keyword: Number(values?.is_except_keyword ?? 1),
                    target_ctr: Number(values?.target_ctr || 0),
                    hidden_ads_count: Number(values?.hidden_ads_count || 0),
                    delay_seconds: Number(values?.delay_seconds || 0)
                },
                attributes: {
                    position: values?.position ? values?.position?.value : POSITION_INVENTORY_OPTIONS_LANG[0]?.value, // left | right
                    impression: values?.impression ? values?.impression?.value : IMPRESSION_INVENTORY_OPTIONS_LANG[0]?.value, // directly | scrolling
                    margin_bottom: `${values?.margin_bottom}px`,
                    margin_left: `${values?.margin_left}px`,
                    margin_right: `${values?.margin_right}px`,
                    width: `${values?.width}`,
                    height: `${values?.height}`,
                    width_1: `${values?.width_1}`,
                    height_1: `${values?.height_1}`,
                },
                status: Number(values?.status ? values?.status?.value : STATUS_INVENTORY_OPTIONS_LANG[0]?.value), // 0 | 1,
                fill_rate_time: Number(values?.fill_rate_time ? values?.fill_rate_time?.value : FILL_RATE_TIME_OPTIONS_LANG[0]?.value)
            };
            const response: any = detail?.id
                ? await putInventory(detail?.id, data)
                : await postInventory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        media_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        parent_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        ad_platform: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        ad_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        ad_id: Yup.string().test('is-required', `${t("This is required")}`,
            function (value) {
                const { ad_platform } = this.parent;
                if (!!ad_platform && ad_platform?.value === '1') {
                    return !!value;
                }
                return true;
            }
        ),
        ad_tracking_code: Yup.string().required(`${t("This is required")}`),
        ad_sub_id: Yup.string().nullable(),
        width: Yup.string().required(`${t("This is required")}`),
        height: Yup.string().required(`${t("This is required")}`),
        width_1: Yup.string().required(`${t("This is required")}`),
        height_1: Yup.string().required(`${t("This is required")}`),
        version: Yup.string().nullable(),
        margin_bottom: Yup.string().required(`${t("This is required")}`),
        margin_left: Yup.string().required(`${t("This is required")}`),
        margin_right: Yup.string().required(`${t("This is required")}`),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        position: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        impression: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }),
        flg_click: Yup.number().nullable(),
        flg_banner_size: Yup.number().nullable(),

        delay_seconds: Yup.string().nullable(),

        fill_rate: Yup.number().nullable(),
        fill_rate_time: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),

        is_block_referral: Yup.number().nullable(),
        is_except_keyword: Yup.number().nullable(),

        target_ctr: Yup.string().required(`${t("This is required")}`),
        hidden_ads_count: Yup.string().required(`${t("This is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            media_code: null,
            parent_code: null,
            ad_platform: null,
            ad_type: null,
            ad_id: "",
            ad_tracking_code: "",
            ad_sub_id: "",
            width: "0",
            height: "0",
            width_1: "0",
            height_1: "0",
            version: "",
            status: null,
            position: null,
            impression: null,
            margin_bottom: "70",
            margin_left: "10",
            margin_right: "10",
            delay_seconds: "0",
            flg_click: 1,
            is_block_referral: 0,
            is_except_keyword: 1,
            flg_banner_size: 0,
            fill_rate: 0,
            fill_rate_time: null,
            target_ctr: '0',
            hidden_ads_count: '3',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_media_code = MEDIA_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.media?.code)
        );
        const v_parent_code = INVENTORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(valueDefault?.parent_code)) || INVENTORY_OPTIONS_LANG[0];

        const v_ad_id = valueDefault?.ad_id;
        const v_ad_tracking_code = valueDefault?.ad_tracking_code;
        const v_ad_sub_id = valueDefault?.ad_sub_id;

        const v_width = valueDefault?.attributes?.width || valueDefault?.width || 0;
        const v_height = valueDefault?.attributes?.height || valueDefault?.height || 0;
        const v_width_1 = valueDefault?.attributes?.width_1 || valueDefault?.width_1 || 0;
        const v_height_1 = valueDefault?.attributes?.height_1 || valueDefault?.height_1 || 0;
        const v_version = valueDefault?.version;

        const v_margin_bottom = (valueDefault?.attributes?.margin_bottom || valueDefault?.margin_bottom)?.replace("px", "");
        const v_margin_left = (valueDefault?.attributes?.margin_left || valueDefault?.margin_left)?.replace("px", "");
        const v_margin_right = (valueDefault?.attributes?.margin_right || valueDefault?.margin_right)?.replace("px", "");

        const v_status = STATUS_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );
        const v_position = POSITION_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.position)
        );
        const v_impression = IMPRESSION_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.attributes?.impression || valueDefault?.impression)
        );

        const v_ad_platform = AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.ad_platform)) || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0];
        const v_ad_type = AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.ad_type)) || AD_TYPE_INVENTORY_OPTIONS_LANG[0];

        const v_flg_click = Number(valueDefault?.configs?.flg_click || 0);
        const v_flg_banner_size = Number(valueDefault?.configs?.flg_banner_size || 0);

        const v_fill_rate = Number(valueDefault?.configs?.fill_rate || 0);
        const v_fill_rate_time = FILL_RATE_TIME_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.fill_rate_time));

        const v_is_block_referral = Number(valueDefault?.configs?.is_block_referral || 0);
        const v_is_except_keyword = Number(valueDefault?.configs?.is_except_keyword ?? 1);

        const v_target_ctr = valueDefault?.configs?.target_ctr || 0;
        const v_hidden_ads_count = valueDefault?.configs?.hidden_ads_count || valueDefault?.hidden_ads_count || 0;

        const v_delay_seconds = String(valueDefault?.configs?.delay_seconds || 0);

        formik.setFieldValue("media_code", v_media_code);

        formik.setFieldValue("parent_code", v_parent_code);
        formik.setFieldValue("ad_platform", v_ad_platform);
        formik.setFieldValue("ad_type", v_ad_type);

        formik.setFieldValue("ad_id", v_ad_id);
        formik.setFieldValue("ad_tracking_code", v_ad_tracking_code);
        formik.setFieldValue("ad_sub_id", v_ad_sub_id);
        formik.setFieldValue("width", v_width);
        formik.setFieldValue("height", v_height);
        formik.setFieldValue("width_1", v_width_1);
        formik.setFieldValue("height_1", v_height_1);
        formik.setFieldValue("version", v_version);
        formik.setFieldValue("status", v_status);
        formik.setFieldValue("position", v_position);
        formik.setFieldValue("impression", v_impression);

        formik.setFieldValue("margin_bottom", v_margin_bottom);
        formik.setFieldValue("margin_left", v_margin_left);
        formik.setFieldValue("margin_right", v_margin_right);

        formik.setFieldValue("flg_click", v_flg_click);
        formik.setFieldValue("flg_banner_size", v_flg_banner_size);

        formik.setFieldValue("fill_rate", v_fill_rate);
        formik.setFieldValue("fill_rate_time", v_fill_rate_time);

        formik.setFieldValue("is_block_referral", v_is_block_referral);

        formik.setFieldValue("is_except_keyword", v_is_except_keyword);

        formik.setFieldValue("target_ctr", v_target_ctr);
        formik.setFieldValue("hidden_ads_count", v_hidden_ads_count);

        formik.setFieldValue("delay_seconds", v_delay_seconds);


    };

    const IS_INVENTORY_TYPE_COUPANG: boolean = (formik?.values?.ad_platform as any)?.value === '1';
    const IS_MAIN_INVENTORY: boolean = !(formik?.values?.parent_code as any)?.value;

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id || detail?.parent_code) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12} className="mt-1">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="media"
                                                        dataList={MEDIA_OPTIONS_LANG || []}
                                                        placeholder={`${t("Media")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        disabled={(!!detail?.id && !!detail?.parent_code) || (!!detail?.media && !!detail?.parent_code)}
                                                        initialValue={formik?.values?.media_code || null}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("media_code", event)
                                                        }
                                                    />
                                                    {formik.errors.media_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.media_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_INVENTORY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                    />
                                                    {formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={8} lg={8}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Main Inventory")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="parent_code"
                                                        dataList={INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Main Inventory")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        disabled={true}
                                                        initialValue={formik?.values?.parent_code || INVENTORY_OPTIONS_LANG[0]}
                                                        onChangeSelect={(event: any) => {
                                                            formik.setFieldValue("parent_code", event);
                                                        }
                                                        }
                                                    />
                                                    {formik.errors.parent_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.parent_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-4 mb-2" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad Platform")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_platform"
                                                                dataList={AD_PLATFORM_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Ad Platform")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={formik?.values?.ad_platform || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue("ad_platform", event);
                                                                    if (event?.value === '2') {
                                                                        formik.setFieldValue("ad_id", '');
                                                                        formik.setFieldValue("ad_sub_id", '');
                                                                    } else {
                                                                        // formik.setFieldValue("parent_code", { value: '', label: t('None') });
                                                                    }
                                                                }
                                                                }
                                                            />
                                                            {formik.errors.ad_platform ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_platform}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Tracking Code")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_tracking_code"
                                                                name="ad_tracking_code"
                                                                autocomplete={false}
                                                                value={formik?.values?.ad_tracking_code}
                                                                placeholder={`${t("Tracking Code")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_tracking_code",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.errors.ad_tracking_code ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_tracking_code}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad ID")}
                                                                <span className="text-danger">{IS_INVENTORY_TYPE_COUPANG ? ' *' : ''}</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_id"
                                                                name="ad_id"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG}
                                                                value={formik?.values?.ad_id}
                                                                placeholder={`${t("Ad ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.errors.ad_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Sub ID")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_sub_id"
                                                                name="ad_sub_id"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG}
                                                                value={formik?.values?.ad_sub_id}
                                                                placeholder={`${t("Sub ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_sub_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.errors.ad_sub_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_sub_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad Type")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_type"
                                                                dataList={AD_TYPE_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Ad Type")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={formik?.values?.ad_type || AD_TYPE_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("ad_type", event)
                                                                }
                                                            />
                                                            {formik.errors.ad_type ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_type}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Impression")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="impression"
                                                                dataList={IMPRESSION_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Impression")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.impression ||
                                                                    IMPRESSION_INVENTORY_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("impression", event)
                                                                }
                                                            // colors={['danger', 'success']}
                                                            />
                                                            {formik.errors.impression ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.impression}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Delay Time")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="delay_seconds"
                                                                name="delay_seconds"
                                                                autocomplete={false}
                                                                value={formik?.values?.delay_seconds}
                                                                placeholder={`${t("Delay Time")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("delay_seconds", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                {t('seconds')}
                                                            </div>
                                                            {formik.errors.delay_seconds ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.delay_seconds}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Width")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="width"
                                                                name="width"
                                                                autocomplete={false}
                                                                value={formik?.values?.width}
                                                                placeholder={`${t("Width")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("width", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.width ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.width}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Height")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="height"
                                                                name="height"
                                                                autocomplete={false}
                                                                value={formik?.values?.height}
                                                                placeholder={`${t("Height")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("height", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.height ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.height}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Position")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="position"
                                                                dataList={POSITION_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Position")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.position ||
                                                                    POSITION_INVENTORY_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("position", event)
                                                                }
                                                            // colors={['danger', 'success']}
                                                            />
                                                            {formik.errors.position ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.position}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Bottom")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_bottom"
                                                                name="margin_bottom"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_bottom}
                                                                placeholder={`${t("Margin Bottom")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_bottom", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_bottom ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_bottom}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Left")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_left"
                                                                name="margin_left"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_left}
                                                                placeholder={`${t("Margin Left")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_left", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_left ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_left}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Right")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_right"
                                                                name="margin_right"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_right}
                                                                placeholder={`${t("Margin Right")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_right", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_right ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_right}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label className="form-label">
                                                                {t("Additional Ad")}
                                                                <span className="text-danger"> *</span>
                                                            </Label>
                                                            <TooltipCustom
                                                                title={t("Hidden Ads Count")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input
                                                                type="text"
                                                                id="hidden_ads_count"
                                                                name="hidden_ads_count"
                                                                autocomplete={false}
                                                                value={formik?.values?.hidden_ads_count}
                                                                placeholder={`${t("Additional Ad")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("hidden_ads_count", s);
                                                                }}
                                                            />
                                                            {formik.errors.hidden_ads_count ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.hidden_ads_count}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label className="form-label">
                                                                {t("Target CTR")}
                                                                <span className="text-danger">{!!(formik?.values?.parent_code as any)?.value ? '*' : ''}</span>
                                                            </Label>
                                                            <TooltipCustom
                                                                title={t("Only in sub inventory")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input
                                                                type="text"
                                                                id="target_ctr"
                                                                name="target_ctr"
                                                                autocomplete={false}
                                                                disabled={IS_MAIN_INVENTORY}
                                                                value={formik?.values?.target_ctr}
                                                                placeholder={`${t("Target CTR")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                                                                    formik.setFieldValue("target_ctr", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                %
                                                            </div>
                                                            {formik.errors.target_ctr ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.target_ctr}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <Col sm={12}>
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="fill_rate"
                                                            name="fill_rate"
                                                            checked={!!formik?.values?.fill_rate}
                                                            onChange={(event: any) => formik.setFieldValue('fill_rate', Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="fill_rate">{t('Fill-Rate')}</Label>
                                                        <TooltipCustom
                                                            title={t("Ad is displayed once every hour")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Col sm={12} className="mt-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Set interval time (hour)")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownOption
                                                            name="fill_rate_time"
                                                            dataList={FILL_RATE_TIME_OPTIONS_LANG || []}
                                                            placeholder={`${t("Fill-Rate Time")}...`}
                                                            className="dropdown-status-rounded"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.fill_rate_time ||
                                                                FILL_RATE_TIME_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("fill_rate_time", event)
                                                            }
                                                        // colors={['danger', 'success']}
                                                        />
                                                        {formik.errors.fill_rate_time ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.fill_rate_time}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </div>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <div className="form-check form-switch mb-0">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flg_click"
                                                        name="flg_click"
                                                        checked={!!formik?.values?.flg_click}
                                                        onChange={(event: any) => formik.setFieldValue('flg_click', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="flg_click">{t('Click Option')}</Label>
                                                    <TooltipCustom
                                                        title={t("When incoming from an external URL, click on the advertisement")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <div className="form-check form-switch mb-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flg_banner_size"
                                                        name="flg_banner_size"
                                                        checked={!!formik?.values?.flg_banner_size}
                                                        onChange={(event: any) => formik.setFieldValue('flg_banner_size', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="flg_banner_size">{t('Size Option')}</Label>
                                                    <TooltipCustom
                                                        title={t("When incoming from an external URL, change the banner size")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Width")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="width_1"
                                                                name="width_1"
                                                                autocomplete={false}
                                                                value={formik?.values?.width_1}
                                                                placeholder={`${t("Width")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("width_1", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.width_1 ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.width_1}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Height")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="height_1"
                                                                name="height_1"
                                                                autocomplete={false}
                                                                value={formik?.values?.height_1}
                                                                placeholder={`${t("Height")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("height_1", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.height_1 ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.height_1}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <div className="form-check form-switch mb-0">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="is_block_referral"
                                                        name="is_block_referral"
                                                        checked={!!formik?.values?.is_block_referral}
                                                        onChange={(event: any) => formik.setFieldValue('is_block_referral', Number(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label" for="is_block_referral">{t('Block Referral')}</Label>
                                                    <TooltipCustom
                                                        title={t("If the referral is empty or main, it's now shown ad")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2" style={{ borderRadius: '5px' }}>
                                                <div className="form-check form-switch mb-0">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="is_except_keyword"
                                                        name="is_except_keyword"
                                                        checked={!!formik?.values?.is_except_keyword}
                                                        onChange={(event: any) => formik.setFieldValue('is_except_keyword', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="is_except_keyword">{t('Block Keyword')}</Label>
                                                    <TooltipCustom
                                                        title={t("If specific keywords is included in referral url, we show ad but it's not working 'Click Option', 'Size Option', only working normally in any cases.")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: "100px" }}
                                                        onClick={() => {
                                                            handleClose && handleClose();
                                                        }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (
                                                        <button
                                                            className="btn btn-primary rounded-pill fs-14"
                                                            color="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                            style={{ width: "150px" }}
                                                        >
                                                            {isLoading ? (
                                                                <Spinner size="sm" className="me-2"></Spinner>
                                                            ) : (
                                                                <>
                                                                    {detail?.id
                                                                        ? t("Button Update Inventory")
                                                                        : t("Button Create Inventory")}
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default FormInventory;
