import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import { STATUS_MONITOR_INSTALLATION_OPTIONS, STATUS_MONITOR_SCRIPT_URL } from "helpers/constans";
import { formatLinkHTTPS } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { changeStatusAlarm, getAllMedias, getMonitors as onGetMonitors, updateStatusAlarmMonitor, updateWebsiteInventory } from "../../../store/thunks";
import MonitorUser from "../MonitorUser";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MonitorScript = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_MONITOR_SCRIPT_URL_LANG = STATUS_MONITOR_SCRIPT_URL?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_MONITOR_INSTALLATION_LANG = STATUS_MONITOR_INSTALLATION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media: withDefault(StringParam, ''),
        install_status: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Monitor;

    const MonitorProperties = createSelector(
        selectLayoutState,
        (state) => ({
            monitors: state.monitors,
            isMonitorSuccess: state.isMonitorSuccess,
            isMonitorLoading: state.isMonitorLoading,
            error: state.error,
        })
    );

    const { monitors, isMonitorLoading } = useSelector(MonitorProperties);

    const [installationStatusSearch, setInstallationStatusSearch] = useState<Option | null>(STATUS_MONITOR_INSTALLATION_LANG?.filter((item) => String(item?.value) === String(query?.install_status))[0]);

    const [scriptStatusSearch, setScriptStatusSearch] = useState<Option | null>(STATUS_MONITOR_SCRIPT_URL_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media || ''))[0]?.id || ''}`
    });

    const [isShowMonitorUser, setIsShowMonitorUser] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetMonitors(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            media: mediaSearch?.value || '',
            install_status: installationStatusSearch?.value || '',
            status: scriptStatusSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            media: '',
            install_status: '',
            status: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setMediaSearch(null);
        setInstallationStatusSearch(null);
        setScriptStatusSearch(null);
    };


    // Begin:: update status Alarm  monitor
    const [itemUpdate, setItemUpdate] = useState<{ id: number, alert_mode: number } | null>(null);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const handleShowConfirmUpdateStatusAlarm = (item: { id: number, alert_mode: number } | null) => {
        setItemUpdate(item);
    };
    const handleOnConfirmUpdate = async () => {
        try {
            if (!itemUpdate) {
                return;
            }
            setIsLoadingUpdate((_prev) => true);
            const response: any = await updateStatusAlarmMonitor(itemUpdate?.id, { alert_mode: itemUpdate?.alert_mode });
            setIsLoadingUpdate((_prev) => false);
            if (response?.data) {
                setItemUpdate((_prev) => null);
                const newState: any = (monitors?.list || [])?.map((item: any) => {
                    if (item?.id === itemUpdate?.id) {
                        return ({ ...item, alert_mode: itemUpdate?.alert_mode });
                    }
                    return item;
                });
                dispatch(changeStatusAlarm({
                    ...monitors,
                    list: newState
                }));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdate((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    // End:: update status Alarm  monitor


    // Begin:: update website
    const [itemUpdateWebsite, setItemUpdateWebsite] = useState<{ id: number, website_url: string } | null>(null);
    const [isLoadingUpdateWebsite, setIsLoadingUpdateWebsite] = useState<boolean>(false);

    const handleUpdateWebsite = async () => {
        try {
            if (!itemUpdateWebsite) {
                return;
            }
            setIsLoadingUpdateWebsite((_prev) => true);
            const response: any = await updateWebsiteInventory(itemUpdateWebsite?.id, { website_url: itemUpdateWebsite?.website_url });
            setIsLoadingUpdateWebsite((_prev) => false);
            if (response?.code === 200) {
                setItemUpdateWebsite((_prev) => null);
                dispatch(onGetMonitors(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateWebsite((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update website

    // Column
    const columns = useMemo(
        () => [
            // {
            //     Header: t(''),
            //     accessor: "stt",
            //     filterable: false,
            //     sortable: true,
            //     thWidth: 70,
            //     thClass: 'align-middle text-start',
            //     Cell: (cell: any) => {
            //         return (<>
            //             <div className="text-start" style={{ minWidth: '50px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
            //         </>)
            //     },
            // },
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Alarm On/Off'),
                accessor: "alert_mode",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 120,
                description: t('on/off to alarm message'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '110px' }}>
                            <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_runing" id="customSwitchsizelg" checked={Boolean(item?.alert_mode)}
                                    onChange={(event: any) => {
                                        if (isHavePermissionRole(ROLES_FOR_APP.MONITOR_SCRIPT_ALARM, userPermissions)) {
                                            handleShowConfirmUpdateStatusAlarm({ id: item?.id, alert_mode: Number(event.target.checked), });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t('Installation Status'),
                accessor: "install_status",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '130px' }}>
                            <span className={`rounded-pill badge bg-${['warning', 'primary', 'success', 'danger'][cell?.value] || 'secondary'}`}>{STATUS_MONITOR_INSTALLATION_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Script Status'),
                accessor: "script_url_status",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '90px' }}>
                            <span className={`rounded-pill badge bg-${['secondary', 'danger', 'success', 'warning'][cell?.value] || 'secondary'}`}>{STATUS_MONITOR_SCRIPT_URL_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = listMedia?.find((itm: any) => String(itm?.id) === String(cell?.value?.id)) || null;
                    return (<>
                        {(!!media && !item?.text_row) && (<div className="d-flex align-items-center" style={{ minWidth: '160px' }}>
                            <div className="me-2">
                                {media?.logo_url ? (
                                    <img src={media?.logo_url || ''} loading="lazy" alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                    <SVGImageDefault style={{ height: '48px' }} />
                                )}
                            </div>
                            <div>
                                <div>
                                    {media?.name || ''}
                                </div>
                                <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website)} target="_blank">
                                    {media?.website || ''}
                                </Link>
                            </div>
                        </div>)}
                    </>)
                },
            },
            {
                Header: t('Website'),
                accessor: "inventory.website_url",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                description: t('Website for installation status'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-start d-flex justify-content-center align-items-center" style={{ minWidth: '130px' }}>
                            <Link className="me-1" to={formatLinkHTTPS(item?.inventory?.website_url)} onClick={(e) => {
                                e.preventDefault();
                                window.open(item?.inventory?.website_url, '_blank');
                            }} target="_blank">
                                <div className="d-flex align-items-center justify-content-center"><i className="ri-links-line fs-16 me-1"></i> {t('Website')}</div>
                            </Link>
                            {isHavePermissionRole(ROLES_FOR_APP.MONITOR_SCRIPT_WEBSITE, userPermissions) && (
                                <div className="ms-1 cursor-pointer" onClick={() => setItemUpdateWebsite({ id: item?.inventory?.id, website_url: item?.inventory?.website_url || '' })}>
                                    <i className="ri-pencil-fill text-primary"></i>
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                Header: t('Banner Size'),
                accessor: "inventory",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const inventory = cell?.value;
                    return (
                        <>
                            {!!item?.text_row ? <strong>{item?.text_row}</strong> : (<div className="d-flex align-items-center" style={{ minWidth: '130px' }}>
                                <div className="flex-shrink-0 me-2">
                                    <SVGImageDefault style={{ height: '48px' }} />
                                </div>
                                <div>
                                    {/* <div className="text-muted ms-2 fs-12">{t('Code')}: {inventory?.id}</div> */}
                                    <strong className="text-muted ms-2">{inventory?.width} * {inventory?.height}</strong>
                                </div>
                            </div>)}
                        </>
                    )
                },
            },
            {
                Header: t('Inventory ID'),
                accessor: "inventory_id",
                filterable: true,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div style={{ minWidth: '90px' }} className="text-center">{item?.inventory?.id}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Tracking Code'),
                accessor: "inventory.ad_tracking_code",
                filterable: true,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div style={{ minWidth: '100px' }}>{item?.inventory?.ad_tracking_code}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Sub ID'),
                accessor: "inventory.ad_sub_id",
                filterable: true,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div style={{ minWidth: '80px' }}>{item?.inventory?.ad_sub_id}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Ad ID'),
                accessor: "inventory.ad_id",
                filterable: true,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div style={{ minWidth: '80px' }}>{item?.inventory?.ad_id}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "last_updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), JSON.stringify(listMedia), STATUS_MONITOR_SCRIPT_URL_LANG, STATUS_MONITOR_INSTALLATION_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => ({
                    label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
                    value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
                }));

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        document.title = `${t("Monitor Script")} - ${t('Monitor')} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Monitor Script')} pageTitle={t('Monitor')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MONITOR_SCRIPT, userPermissions) && (
                        <Row>
                            <Col sm={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={monitors?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.MONITOR_USER, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setIsShowMonitorUser((_prev) => true)}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Add Monitor')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <div className="d-flex flex-column flex-md-row g-4 align-items-end mb-2 mt-2">
                                            <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mt-0">
                                                <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-0 bg-primary-subtle text-primary border-0">
                                                    <CardBody>
                                                        <div className="d-flex align-items-center ">
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Status_Installation_0')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                            <div>
                                                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                    <span className="counter-value text-primary">
                                                                        {(isMonitorLoading && query?.page === 1) ? <Spinner size="sm" ></Spinner> : (
                                                                            <CountUp
                                                                                start={0}
                                                                                end={monitors?.uninstalled_count || 0}
                                                                                duration={1}
                                                                            />
                                                                        )}
                                                                    </span></h4>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-0 bg-primary-subtle text-primary border-0">
                                                    <CardBody>
                                                        <div className="d-flex align-items-center ">
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Status_Url_Script_1')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                            <div>
                                                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                    <span className="counter-value text-primary">
                                                                        {(isMonitorLoading && query?.page === 1) ? <Spinner size="sm" ></Spinner> : (
                                                                            <CountUp
                                                                                start={0}
                                                                                end={monitors?.unavailable_count || 0}
                                                                                duration={1}
                                                                            />
                                                                        )}
                                                                    </span></h4>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="w-100">
                                                <Row className="g-4 align-items-center mt-0">
                                                    <Col sm={6} md={3} lg={3} xxl={3} className='mt-3 mt-md-2'>
                                                        <LabelWrapper label={t('Installation Status')} isShow={!!installationStatusSearch?.value}>
                                                            <DropdownStatus
                                                                name="install_status"
                                                                dataList={STATUS_MONITOR_INSTALLATION_LANG || []}
                                                                placeholder={`${t("Installation Status")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={installationStatusSearch || null}
                                                                onChangeSelect={(e: any) => setInstallationStatusSearch(e)}
                                                                isHasOptionAll={true}
                                                                optionAll={{ label: t('All Installation Status'), value: '' }}
                                                                colors={['warning', 'primary', 'success', 'danger',]}
                                                            />
                                                        </LabelWrapper>
                                                    </Col>
                                                    <Col sm={6} md={3} lg={3} xxl={3} className='mt-3 mt-md-2'>
                                                        <LabelWrapper label={t('Script Status')} isShow={!!scriptStatusSearch?.value}>
                                                            <DropdownStatus
                                                                name="script_status"
                                                                dataList={STATUS_MONITOR_SCRIPT_URL_LANG || []}
                                                                placeholder={`${t("Script Status")}...`}
                                                                className="dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={scriptStatusSearch || null}
                                                                onChangeSelect={(e: any) => setScriptStatusSearch(e)}
                                                                isHasOptionAll={true}
                                                                optionAll={{ label: t('All Script Status'), value: '' }}
                                                                colors={['secondary', 'danger', 'success', 'warning']}
                                                            />
                                                        </LabelWrapper>
                                                    </Col>
                                                    <Col md={6} lg={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary me-2 "
                                                                onClick={searchData}
                                                                disabled={isMonitorLoading}
                                                            >
                                                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                                {t('Button Search')}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary fs-14"
                                                                onClick={resetData}
                                                            >
                                                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                                {t('Button Reset')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={monitors?.list?.length ? monitors?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={monitors?.pagination?.total}
                                            customPageCount={Math.ceil(Number(monitors?.pagination?.total) / Number(monitors?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isMonitorLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
                <Modal
                    isOpen={itemUpdateWebsite !== null}
                    centered={true}
                    size="md"
                    toggle={() => setItemUpdateWebsite((_prev) => null)}
                    keyboard={true}
                >
                    <ModalHeader toggle={() => setItemUpdateWebsite((_prev) => null)}>
                        {t('Update Website')}
                    </ModalHeader>
                    <ModalBody className="">
                        <Col md={12} lg={12}>
                            <div>
                                <label className="form-label">
                                    {t("Website")}
                                    <span className="text-danger"> *</span>
                                </label>
                                <Input
                                    type="textarea"
                                    rows={3}
                                    id="website"
                                    name="website"
                                    autocomplete={false}
                                    value={itemUpdateWebsite?.website_url}
                                    placeholder={`${t("Website")}...`}
                                    onChange={(event: any) => {
                                        setItemUpdateWebsite((prev: any) => ({ ...prev, website_url: event?.target?.value || '' }))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                <button
                                    className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                    color="light"
                                    type="button"
                                    disabled={isLoadingUpdateWebsite}
                                    style={{ width: '100px' }}
                                    onClick={() => setItemUpdateWebsite((_prev) => null)}
                                >
                                    {t("Button Close")}
                                </button>
                                <button
                                    className="btn btn-primary rounded-pill fs-14"
                                    color="success"
                                    type="button"
                                    disabled={isLoadingUpdateWebsite}
                                    style={{ width: '150px' }}
                                    onClick={() => handleUpdateWebsite()}
                                >
                                    {isLoadingUpdateWebsite ? (
                                        <Spinner size="sm" className="me-2"></Spinner>
                                    ) :
                                        <> {t("Button Update")}</>}
                                </button>
                            </div>
                        </Col>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={!!isShowMonitorUser}
                    centered={true}
                    size="xl"
                    toggle={() => setIsShowMonitorUser((_prev) => false)}
                    keyboard={true}
                >
                    <ModalHeader toggle={() => setIsShowMonitorUser((_prev) => false)}>
                        {t('Button Add Monitor')}
                    </ModalHeader>
                    <ModalBody className="">
                        <MonitorUser />
                    </ModalBody>
                </Modal>
                <ModalConfirm
                    textButtonConfirm={t('Button Update')}
                    classButtonConfirm='btn-primary'
                    classIconButtonConfirm=''
                    header={itemUpdate?.alert_mode ? t('Confirm to ON') : t('Confirm to OFF')}
                    title={t('Are you sure you want to update this?')}
                    content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
                    isShowIcon={false}
                    isOpen={!!itemUpdate}
                    isLoading={isLoadingUpdate}
                    onClose={() => setItemUpdate((prev: any) => null)}
                    onConfirm={handleOnConfirmUpdate}
                />
            </div>
        </React.Fragment>
    );
};

export default MonitorScript;