import { createSlice } from "@reduxjs/toolkit";
import { IAgencyReport } from "api/types/_agency";
import { IInventoryReport } from "api/types/_inventory";
import { IDailyReport, IHourlyReport, IMediaReport } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IRevenueReport } from "api/types/_revenue";
import { reportADPlatforms, reportAgencies, reportCommissions, reportDaily, reportHourly, reportInventories, reportMedias, reportMediasV2, reportRevenues, reportSettlements } from "./thunk";
import { ICommissionReport } from "api/types/_commission";
import { ISettlementReport } from "api/types/_settlement";

export interface IState {
  medias: ResponseData<IMediaReport[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  mediasV2: ResponseData<IMediaReport[]> & PaginationResponse | null,
  isMediaV2Loading: boolean,
  isMediaV2Success: boolean,

  inventories: ResponseData<IInventoryReport[]> & PaginationResponse | null,
  isInventoryLoading: boolean,
  isInventorySuccess: boolean,

  agencies: ResponseData<IAgencyReport[]> & PaginationResponse | null,
  isAgencyLoading: boolean,
  isAgencySuccess: boolean,

  revenues: ResponseData<IRevenueReport[]> & PaginationResponse | null,
  isRevenueLoading: boolean,
  isRevenueSuccess: boolean,

  commissions: ResponseData<ICommissionReport[]> & PaginationResponse | null,
  isCommissionLoading: boolean,
  isCommissionSuccess: boolean,

  settlements: ResponseData<ISettlementReport[]> & PaginationResponse | null,
  isSettlementLoading: boolean,
  isSettlementSuccess: boolean,

  daily: ResponseData<IDailyReport[]> & PaginationResponse | null,
  isDailyLoading: boolean,
  isDailySuccess: boolean,

  hourly: ResponseData<IHourlyReport[]> & PaginationResponse | null,
  isHourlyLoading: boolean,
  isHourlySuccess: boolean,

  ad_platforms: ResponseData<IInventoryReport[]> & PaginationResponse | null,
  isADPlatformLoading: boolean,
  isADPlatformSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  mediasV2: null,
  isMediaV2Loading: false,
  isMediaV2Success: false,

  inventories: null,
  isInventoryLoading: false,
  isInventorySuccess: false,

  agencies: null,
  isAgencyLoading: false,
  isAgencySuccess: false,

  revenues: null,
  isRevenueLoading: false,
  isRevenueSuccess: false,

  commissions: null,
  isCommissionLoading: false,
  isCommissionSuccess: false,

  settlements: null,
  isSettlementLoading: false,
  isSettlementSuccess: false,

  daily: null,
  isDailyLoading: false,
  isDailySuccess: false,

  hourly: null,
  isHourlyLoading: false,
  isHourlySuccess: false,

  ad_platforms: null,
  isADPlatformLoading: false,
  isADPlatformSuccess: false,

  error: {},
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report medias
    builder.addCase(reportMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(reportMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(reportMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //report medias v2
    builder.addCase(reportMediasV2.pending, (state: IState, action: any) => {
      state.isMediaV2Loading = true
    });
    builder.addCase(reportMediasV2.fulfilled, (state: IState, action: any) => {
      state.mediasV2 = action.payload.data;
      state.isMediaV2Success = true;
      state.isMediaV2Loading = false;
    });
    builder.addCase(reportMediasV2.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaV2Success = false;
      state.isMediaV2Loading = false;
    });

    //report Inventories
    builder.addCase(reportInventories.pending, (state: IState, action: any) => {
      state.isInventoryLoading = true
    });
    builder.addCase(reportInventories.fulfilled, (state: IState, action: any) => {
      state.inventories = action.payload.data;
      state.isInventorySuccess = true;
      state.isInventoryLoading = false;
    });
    builder.addCase(reportInventories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventorySuccess = false;
      state.isInventoryLoading = false;
    });

    //report Agencies
    builder.addCase(reportAgencies.pending, (state: IState, action: any) => {
      state.isAgencyLoading = true
    });
    builder.addCase(reportAgencies.fulfilled, (state: IState, action: any) => {
      state.agencies = action.payload.data;
      state.isAgencySuccess = true;
      state.isAgencyLoading = false;
    });
    builder.addCase(reportAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAgencySuccess = false;
      state.isAgencyLoading = false;
    });

    //report Revenues
    builder.addCase(reportRevenues.pending, (state: IState, action: any) => {
      state.isRevenueLoading = true
    });
    builder.addCase(reportRevenues.fulfilled, (state: IState, action: any) => {
      state.revenues = action.payload.data;
      state.isRevenueSuccess = true;
      state.isRevenueLoading = false;
    });
    builder.addCase(reportRevenues.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRevenueSuccess = false;
      state.isRevenueLoading = false;
    });

    //report Commissions
    builder.addCase(reportCommissions.pending, (state: IState, action: any) => {
      state.isCommissionLoading = true
    });
    builder.addCase(reportCommissions.fulfilled, (state: IState, action: any) => {
      state.commissions = action.payload.data;
      state.isCommissionSuccess = true;
      state.isCommissionLoading = false;
    });
    builder.addCase(reportCommissions.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCommissionSuccess = false;
      state.isCommissionLoading = false;
    });

    //report Settlements
    builder.addCase(reportSettlements.pending, (state: IState, action: any) => {
      state.isSettlementLoading = true
    });
    builder.addCase(reportSettlements.fulfilled, (state: IState, action: any) => {
      state.settlements = action.payload.data;
      state.isSettlementSuccess = true;
      state.isSettlementLoading = false;
    });
    builder.addCase(reportSettlements.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSettlementSuccess = false;
      state.isSettlementLoading = false;
    });

    //report Daily
    builder.addCase(reportDaily.pending, (state: IState, action: any) => {
      state.isDailyLoading = true
    });
    builder.addCase(reportDaily.fulfilled, (state: IState, action: any) => {
      state.daily = action.payload.data;
      state.isDailySuccess = true;
      state.isDailyLoading = false;
    });
    builder.addCase(reportDaily.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDailySuccess = false;
      state.isDailyLoading = false;
    });

    //report Hourly
    builder.addCase(reportHourly.pending, (state: IState, action: any) => {
      state.isHourlyLoading = true
    });
    builder.addCase(reportHourly.fulfilled, (state: IState, action: any) => {
      state.hourly = action.payload.data;
      state.isHourlySuccess = true;
      state.isHourlyLoading = false;
    });
    builder.addCase(reportHourly.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHourlySuccess = false;
      state.isHourlyLoading = false;
    });


    //report AD Platform
    builder.addCase(reportADPlatforms.pending, (state: IState, action: any) => {
      state.isADPlatformLoading = true
    });
    builder.addCase(reportADPlatforms.fulfilled, (state: IState, action: any) => {
      state.ad_platforms = action.payload.data;
      state.isADPlatformSuccess = true;
      state.isADPlatformLoading = false;
    });
    builder.addCase(reportADPlatforms.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isADPlatformSuccess = false;
      state.isADPlatformLoading = false;
    });
  },
});

export default ReportSlice.reducer;