import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import inventoryApi from 'api/inventoryApi';
import { formatQueryParams } from "helpers/format";
import credentialApi from "api/credentialApi";
import agencyApi from "api/agencyApi";

export const getMedias = createAsyncThunk("Medias" , async (params: any = {}) => {
  try{
    const response = await mediaApi.medias(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getHistoryConfigCPM = createAsyncThunk("History Config CPM" , async (params: any = {}) => {
  try{
    const response = await inventoryApi.getHistoryConfigCPM(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getInventories = createAsyncThunk("Inventories" , async (params: any = {}) => {
  try{
    const response = await inventoryApi.inventories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getCredentials = createAsyncThunk("Credentials" , async (params: any = {}) => {
  try{
    const response = await credentialApi.credentials(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getAgencies = createAsyncThunk("Agencies" , async (params: any = {}) => {
  try{
    const response = await agencyApi.agencies(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

// =========== Begin: Media ===========
export const getMedia = async (id: number) => {
  try{
    const response = await mediaApi.getMedia(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try{
    const response = await mediaApi.postMedia(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.putMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putAccountMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.putAccountMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllMedias =  async(params: any = {}) => {
  try{
    const response = await mediaApi.allMedias(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getGenerateApiKeyMedia = async (id: number, params: any = {}) => {
  try{
    const response = await mediaApi.getGenerateApiKeyMedia(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

// =========== End: Media ===========

// =========== Begin: Inventory ===========
export const getAllInventories =  async(params: any = {}) => {
  try{
    const response = await inventoryApi.allInventories(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getInventoryByNormal = async (params: any = {}) => {
  try{
    const response = await inventoryApi.inventories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const getInventory = async (id: number) => {
  try{
    const response = await inventoryApi.getInventory(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postInventory = async (data: any = {}) => {
  try{
    const response = await inventoryApi.postInventory(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.deleteInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const updateWebsiteInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putWebsiteInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const updateMemoInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putMemoInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const updateConfigInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putConfigInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

// =========== End: Inventory ===========

export const uploadImage = async (data: any = {}) => {
  try{
    const response = await inventoryApi.uploadImage(data);
    return response;
  }catch (error) {
    return error;
  }
};

// =========== Begin: Config CPM ===========
export const getConfigCPMs = async (params: any = {}) => {
  try{
    const response = await inventoryApi.getConfigCPMs(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const addConfigCPM = async (data: any = {}) => {
  try{
    const response = await inventoryApi.postConfigCPM(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const updateConfigCPM = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putConfigCPM(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteConfigCPM = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.deleteConfigCPM(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
// =========== End: Config CPM ===========

// =========== Begin: Credential ===========

export const getCredential = async (id: number) => {
  try{
    const response = await credentialApi.getCredential(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postCredential = async (data: any = {}) => {
  try{
    const response = await credentialApi.postCredential(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putCredential = async (id:number, data: any = {}) => {
  try{
    const response = await credentialApi.putCredential(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteCredential = async (id:number, data: any = {}) => {
  try{
    const response = await credentialApi.deleteCredential(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllCredentials =  async(params: any = {}) => {
  try{
    const response = await credentialApi.allCredentials(params)
    return response;
  }catch (error) {
    return error;
  }
};

// =========== End: Credential ===========


// =========== Begin: Agency ===========
export const getAgency = async (id: number) => {
  try{
    const response = await agencyApi.getAgency(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postAgency = async (data: any = {}) => {
  try{
    const response = await agencyApi.postAgency(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putAgency = async (id:number, data: any = {}) => {
  try{
    const response = await agencyApi.putAgency(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteAgency = async (id:number, data: any = {}) => {
  try{
    const response = await agencyApi.deleteAgency(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putAccountAgency = async (id:number, data: any = {}) => {
  try{
    const response = await agencyApi.putAccountAgency(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllAgencies =  async(params: any = {}) => {
  try{
    const response = await agencyApi.allAgencies(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getGenerateApiKeyAgency = async (id: number, params: any = {}) => {
  try{
    const response = await agencyApi.getGenerateApiKeyAgency(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

// =========== End: Agency ===========