import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/allRoutes';

const SearchOptionPages = () => {
    const { t } = useTranslation();
    const { userPermissions } = useRole();
    const [value, setValue] = useState("");
    const onChangeData = (value: any) => {
        setValue(value);
    };

    const menuItems: any = [
        // {
        //     label: t('Dashboards'),
        //     link: ROUTES.DASHBOARD,
        //     icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        // },
        {
            label: t('Media Management'),
            link: ROUTES.MANAGEMENT_MEDIA,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_MEDIA, userPermissions),
        },
        {
            label: t('Inventory Management'),
            link: ROUTES.MANAGEMENT_INVENTORY,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_INVENTORY, userPermissions),
        },
        {
            label: t('Agency Management'),
            link: ROUTES.MANAGEMENT_AGENCY,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_AGENCY, userPermissions),
        },
        {
            label: t('API Management'),
            link: ROUTES.MANAGEMENT_API,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_API, userPermissions),
        },
        {
            label: t('Report Media'),
            link: ROUTES.REPORT_MEDIA,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA, userPermissions),
        },
        {
            label: t('Report Media v2'),
            link: ROUTES.REPORT_MEDIA_V2,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA_V2, userPermissions),
        },
        {
            label: t('Report Inventory'),
            link: ROUTES.REPORT_INVENTORY,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_INVENTORY, userPermissions),
        },
        {
            label: t('Report AD Platform'),
            link: ROUTES.REPORT_AD_PLATFORM,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AD_PLATFORM, userPermissions),
        },
        {
            label: t('Report Agency'),
            link: ROUTES.REPORT_AGENCY,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AGENCY, userPermissions),
        },
        {
            label: t('Report Revenue'),
            link: ROUTES.REPORT_REVENUE,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_REVENUE, userPermissions),
        },
        {
            label: t('Report Commission'),
            link: ROUTES.REPORT_COMMISSION,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_COMMISSION, userPermissions),
        },
        {
            label: t('Daily Statistics'),
            link: ROUTES.DAILY_STATISTICS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.DAILY_STATISTICS, userPermissions),
        },
        {
            label: t('Hourly Statistics'),
            link: ROUTES.HOURLY_STATISTICS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.HOURLY_STATISTICS, userPermissions),
        },
        {
            label: t('Ads Log'),
            link: ROUTES.ADS_LOG,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.ADS_LOG, userPermissions),
        },
        {
            label: t('Monitor Script'),
            link: ROUTES.MONITOR_SCRIPT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_SCRIPT, userPermissions),
        },
        {
            label: t('IP Statistics'),
            link: ROUTES.IP_STATISTICS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.IP_STATISTICS, userPermissions),
        },
        {
            label: t('UUID Statistics'),
            link: ROUTES.UUID_STATISTICS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.UUID_STATISTICS, userPermissions),
        },
        {
            label: t('UUID Except'),
            link: ROUTES.UUID_EXCEPT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.UUID_EXCEPT, userPermissions),
        },
        {
            label: t('UUID Block'),
            link: ROUTES.UUID_BLOCKS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.UUID_BLOCKS, userPermissions),
        },
        {
            label: t('Monitor Configuration'),
            link: ROUTES.MONITOR_CONFIGURATION,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_CONFIGURATION, userPermissions),
        },
        {
            label: t('User'),
            link: ROUTES.USER_LIST,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions),
        },
        // {
        //     label: t('Role'),
        //     link: ROUTES.USER_LIST,
        //     icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions),
        // },
        {
            label: t('Admin Activity Logs'),
            link: ROUTES.ADMIN_ACTIVITY_LOGS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.ADMIN_ACTIVITY_LOGS, userPermissions),
        },
        {
            label: t('Proxy Management'),
            link: ROUTES.MANAGEMENT_PROXY,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_PROXY, userPermissions),
        },
    ];

    useEffect(() => {
        var searchOptions: any = document.getElementById("search-close-options");
        var dropdown: any = document.getElementById("search-dropdown");
        var searchInput: any = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e: any) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
    }, []);

    const checkIsIncludeText = (text: string, search: string) => {
        return String(text).toUpperCase().includes(String(search).toUpperCase());
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-sm-block">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                            onChangeData(e.target.value);
                        }} />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar style={{ height: "320px" }}>

                        <div className="dropdown-header mt-2">
                            <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('Pages')}</h6>
                        </div>

                        {menuItems?.map((item: any, index: number) => (
                            <Link key={index} to={item?.link} className={`dropdown-item notify-item ${(!!item?.isShow && checkIsIncludeText(item?.label || '', value || '')) ? '' : 'd-none'}`}>
                                {item?.icon}
                                <span className="">{item?.label}</span>
                            </Link>
                        ))}
                    </SimpleBar>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOptionPages;