import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IConfigCPM, IInventory, IInventoryReport, IUploadImage } from "./types/_inventory";
import { PaginationResponse, ResponseData } from "./types/_public";

const api = new APIClient();

const path = '/inventories';
const path_cpm = '/cpm';
const inventoryApi = {
  inventories(params: any):Promise<AxiosResponse<ResponseData<IInventory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getInventory(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postInventory(dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putWebsiteInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}/website-url`;
    return api.put(url, dataForm)
  },
  putMemoInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}/memo`;
    return api.put(url, dataForm)
  },
  putConfigInventory(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path}/${id}/configs`;
    return api.put(url, dataForm)
  },
  uploadImage(dataForm: any) : Promise<AxiosResponse<IUploadImage[], any>>  {
    const url = `upload/r2-files`;
    // const formData = new FormData();
    // formData.append('file', file);
    return api.post(url, dataForm, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  inventoryReports(params: any = {}) : Promise<AxiosResponse<ResponseData<IInventoryReport>, any>>  {
    const url = `/reports/inventory`;
    return api.get(url, params)
  },
  ADPlatformReports(params: any = {}) : Promise<AxiosResponse<ResponseData<IInventoryReport>, any>>  {
    const url = `/reports/inventory/ad-platform`;
    return api.get(url, params)
  },
  allInventories(params: any = {}) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `/masters/inventories/all`;
    return api.get(url, params)
  },
  getConfigCPMs(params: any = {}) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path_cpm}/configs`;
    return api.get(url, params)
  },
  getHistoryConfigCPM(params: any = {}) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path_cpm}/histories`;
    return api.get(url, params)
  },
  postConfigCPM(dataForm: any) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path_cpm}/configs`;
    return api.post(url, dataForm)
  },
  putConfigCPM(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path_cpm}/configs/${id}`;
    return api.put(url, dataForm)
  },
  deleteConfigCPM(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<IConfigCPM>, any>>  {
    const url = `${path_cpm}/configs/${id}`;
    return api.delete(url, dataForm)
  },
}
export default inventoryApi
