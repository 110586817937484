import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/User/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import RoleList from "pages/User/Role";
import UserList from "pages/User/User";

import Configuration from "pages/Configuration";

import ManagementInventory from "pages/Managements/Inventory";
import ManagementMedia from "pages/Managements/Media";

import AdsLog from "pages/AdsLog/TrackingLog";
import ManagementApi from "pages/Managements/Api";
import MonitorScript from "pages/Monitor/MonitorScript";
import ReportInventory from "pages/Reports/Inventory";
import ReportMedia from "pages/Reports/Media";
import ManagementAgency from "pages/Managements/Agency";
import AdminActivityLogs from "pages/Logging/AdminActivityLogs";
import IPStatistic from "pages/Statistics/IPStatistic";
import ReportAgency from "pages/Reports/Agency";
import ManagementProxy from "pages/Managements/Proxy";
import ReportRevenue from "pages/Reports/Revenue";
import UUIDStatistic from "pages/Statistics/UUIDStatistic";
import ReportCommission from "pages/Reports/Commission";
import DailyStatistic from "pages/Statistics/DailyStatistic";
import UUIDBlock from "pages/Monitor/UUIDBlock";
import HourlyStatistic from "pages/Statistics/HourlyStatistic";
import UUIDExcept from "pages/Monitor/UUIDExcept";
import MonitorConfiguration from "pages/Monitor/MonitorConfiguration";
import ReportSettlement from "pages/Reports/Settlement";
import ReportMediaV2 from "pages/Reports/MediaV2";
import ReportADPlatform from "pages/Reports/ADPlatform";

const ROUTES = {
  DASHBOARD: '/dashboard',

  PROFILE: '/profile',
  PROFILE_SETTING: '/pages-profile-settings',

  USER_LIST: '/user/list',
  USER_ROLE: '/user/role',
  USER_PROFILE: '/user/profile',
  MANAGEMENT_PROXY: '/user/proxy',

  ADMIN_ACTIVITY_LOGS: '/logging/admin-activity-logs',

  CONFIGURATION: '/configuration',

  MANAGEMENT_MEDIA: '/managements/media',
  MANAGEMENT_INVENTORY: '/managements/inventory',
  MANAGEMENT_AGENCY: '/managements/agency',
  MANAGEMENT_API: '/managements/api',

  MONITOR_SCRIPT: '/monitor/monitor-script',
  UUID_BLOCKS: '/monitor/block-uuid',
  UUID_EXCEPT: '/monitor/except-uuid',
  MONITOR_CONFIGURATION: '/monitor/configuration',


  REPORT_MEDIA: '/reports/media',
  REPORT_MEDIA_V2: '/reports/media-v2',
  REPORT_INVENTORY: '/reports/inventory',
  REPORT_AD_PLATFORM: '/reports/ad-platform',
  REPORT_AGENCY: '/report/agency',
  REPORT_REVENUE: '/report/revenue',
  REPORT_COMMISSION: '/report/commission',
  REPORT_SETTLEMENT: '/report/settlement',


  ADS_LOG: '/statistics/ads-log',
  UUID_STATISTICS: '/statistics/uuid-statistics',
  DAILY_STATISTICS: '/statistics/daily-statistics',
  HOURLY_STATISTICS: '/statistics/hourly-statistics',

  IP_STATISTICS: '/lab/ip-statistics',



}

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.USER_LIST, component: <UserList /> },
  { path: ROUTES.USER_ROLE, component: <RoleList /> },
  { path: ROUTES.USER_PROFILE, component: <Profile /> },

  { path: ROUTES.CONFIGURATION, component: <Configuration /> },

  { path: ROUTES.MANAGEMENT_MEDIA, component: <ManagementMedia /> },
  { path: ROUTES.MANAGEMENT_INVENTORY, component: <ManagementInventory /> },
  { path: ROUTES.MANAGEMENT_AGENCY, component: <ManagementAgency /> },
  { path: ROUTES.MANAGEMENT_API, component: <ManagementApi /> },
  { path: ROUTES.MANAGEMENT_PROXY, component: <ManagementProxy /> },

  { path: ROUTES.REPORT_MEDIA, component: <ReportMedia /> },
  { path: ROUTES.REPORT_MEDIA_V2, component: <ReportMediaV2 /> },
  { path: ROUTES.REPORT_INVENTORY, component: <ReportInventory /> },
  { path: ROUTES.REPORT_AD_PLATFORM, component: <ReportADPlatform /> },
  { path: ROUTES.REPORT_AGENCY, component: <ReportAgency /> },
  { path: ROUTES.REPORT_REVENUE, component: <ReportRevenue /> },
  { path: ROUTES.REPORT_COMMISSION, component: <ReportCommission /> },
  { path: ROUTES.DAILY_STATISTICS, component: <DailyStatistic /> },
  { path: ROUTES.HOURLY_STATISTICS, component: <HourlyStatistic /> },
  { path: ROUTES.REPORT_SETTLEMENT, component: <ReportSettlement /> },

  { path: ROUTES.ADS_LOG, component: <AdsLog /> },
  { path: ROUTES.MONITOR_SCRIPT, component: <MonitorScript /> },
  { path: ROUTES.IP_STATISTICS, component: <IPStatistic /> },
  { path: ROUTES.UUID_STATISTICS, component: <UUIDStatistic /> },
  { path: ROUTES.UUID_EXCEPT, component: <UUIDExcept /> },
  { path: ROUTES.UUID_BLOCKS, component: <UUIDBlock /> },
  { path: ROUTES.MONITOR_CONFIGURATION, component: <MonitorConfiguration /> },


  { path: ROUTES.ADMIN_ACTIVITY_LOGS, component: <AdminActivityLogs /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };

