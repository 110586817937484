import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IIP, IUUID } from "api/types/_statistic";
import { ipStatistics, uuidBlocks, uuidBlocksAds, uuidStatistics } from "./thunk";


export interface IState {
  ipStatistics: ResponseData<IIP[]> & PaginationResponse | null,
  isIpStatisticLoading: boolean,
  isIpStatisticSuccess: boolean,

  uuidStatistics: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDStatisticLoading: boolean,
  isUUIDStatisticSuccess: boolean,

  uuidBlocks: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDBlockLoading: boolean,
  isUUIDBlockSuccess: boolean,

  uuidBlocksAds: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDBlockAdsLoading: boolean,
  isUUIDBlockAdsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  ipStatistics: null,
  isIpStatisticLoading: false,
  isIpStatisticSuccess: false,

  uuidStatistics: null,
  isUUIDStatisticLoading: false,
  isUUIDStatisticSuccess: false,

  uuidBlocks: null,
  isUUIDBlockLoading: false,
  isUUIDBlockSuccess: false,

  uuidBlocksAds: null,
  isUUIDBlockAdsLoading: false,
  isUUIDBlockAdsSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {
    changeStatusBlockIPAction(state: any, action : any) {
      state.ipStatistics = action.payload;
    },
    changeStatusBlockUUIDAction(state: any, action : any) {
      state.uuidStatistics = action.payload;
    },
  },
  extraReducers: (builder) => {
    // IP Statistics
    builder.addCase(ipStatistics.pending, (state: IState, action: any) => {
      state.isIpStatisticLoading = true
    });
    builder.addCase(ipStatistics.fulfilled, (state: IState, action: any) => {
      state.ipStatistics = action.payload.data;
      state.isIpStatisticSuccess = true;
      state.isIpStatisticLoading = false;
    });
    builder.addCase(ipStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isIpStatisticSuccess = false;
      state.isIpStatisticLoading = false;
    });

    // UUID Statistics
    builder.addCase(uuidStatistics.pending, (state: IState, action: any) => {
      state.isUUIDStatisticLoading = true
    });
    builder.addCase(uuidStatistics.fulfilled, (state: IState, action: any) => {
      state.uuidStatistics = action.payload.data;
      state.isUUIDStatisticSuccess = true;
      state.isUUIDStatisticLoading = false;
    });
    builder.addCase(uuidStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDStatisticSuccess = false;
      state.isUUIDStatisticLoading = false;
    });

    // UUID Blocks
    builder.addCase(uuidBlocks.pending, (state: IState, action: any) => {
      state.isUUIDBlockLoading = true
    });
    builder.addCase(uuidBlocks.fulfilled, (state: IState, action: any) => {
      state.uuidBlocks = action.payload.data;
      state.isUUIDBlockSuccess = true;
      state.isUUIDBlockLoading = false;
    });
    builder.addCase(uuidBlocks.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDBlockSuccess = false;
      state.isUUIDBlockLoading = false;
    });

    // UUID Blocks Ads
    builder.addCase(uuidBlocksAds.pending, (state: IState, action: any) => {
      state.isUUIDBlockAdsLoading = true
    });
    builder.addCase(uuidBlocksAds.fulfilled, (state: IState, action: any) => {
      state.uuidBlocksAds = action.payload.data;
      state.isUUIDBlockAdsSuccess = true;
      state.isUUIDBlockAdsLoading = false;
    });
    builder.addCase(uuidBlocksAds.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDBlockAdsSuccess = false;
      state.isUUIDBlockAdsLoading = false;
    });
  },
});

export const {
  changeStatusBlockIPAction, changeStatusBlockUUIDAction
} = StatisticSlice.actions;

export default StatisticSlice.reducer;