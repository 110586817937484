import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import Hashtag from "components/Common/Hashtag";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardHeader, Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getMonitorSystemUuidConfigs, getExceptKeywords as onGetExceptKeywords, postExceptKeyword } from "../../../store/thunks";
import { formatNumberWithCommas } from "helpers/format";

interface Tag {
    id: string;
    text: string
}
const MonitorConfiguration = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();

    const [keywords, setKeywords] = useState<Tag[]>([]);

    const [autoBlockUuid, setAutoBlockUuid] = useState<number | boolean>(0);

    const [exceedRequestPerHour, setExceedRequestPerHour] = useState<number | string>(0);

    const [belowClickPerHour, setBelowClickPerHour] = useState<number | string>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Monitor;

    const MonitorProperties = createSelector(
        selectLayoutState,
        (state) => ({
            exceptKeywords: state.exceptKeywords,
            isExceptKeywordSuccess: state.isExceptKeywordSuccess,
            isExceptKeywordLoading: state.isExceptKeywordLoading,
            error: state.error,
        })
    );

    const { exceptKeywords, isExceptKeywordLoading } = useSelector(MonitorProperties);

    const handleUpdateExceptKeywords = async () => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                auto_block_uuid: Number(autoBlockUuid),
                exceed_request_per_hour: Number(exceedRequestPerHour),
                below_click_per_hour: Number(belowClickPerHour),
                keywords: keywords?.map((key) => key?.text) || [],

            };
            const response: any = await postExceptKeyword(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        dispatch(onGetExceptKeywords({}));
    }, []);

    useEffect(() => {
        if (exceptKeywords) {
            setKeywords((prev) => (exceptKeywords?.map((item: string) => ({ id: item, text: item }))));
        }
    }, [exceptKeywords]);

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res]: any = await Promise.all([getMonitorSystemUuidConfigs()]);

                const configs = res?.data || null;

                setAutoBlockUuid(Number(configs?.auto_block_uuid));

                setExceedRequestPerHour(configs?.exceed_request_per_hour || 0);

                setBelowClickPerHour(configs?.below_click_per_hour || 0);

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        document.title = `${t("Monitor Configuration")} - ${t('Monitor')} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Monitor Configuration')} pageTitle={t('Monitor')} />
                    <Row>
                        <Col sm={12} md={12}>
                            <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">

                                <div className="card-body mt-0 pb-4">
                                    <Row className="g-4 align-items-center mt-0">
                                        <Col sm={0} md={2}></Col>
                                        <Col sm={12} md={8} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                            <div className="border border-dashed border-primary p-4 position-relative" style={{ borderRadius: '8px' }}>
                                                <span className="h6 position-absolute text-primary mb-0" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -50%)',
                                                    backgroundColor: 'var(--vz-card-bg)',
                                                    padding: '0 5px',
                                                }}
                                                >{t('Automatic Block UUID')}</span>
                                                <Col sm={12} className="mb-3">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="auto_block_uuid"
                                                            name="auto_block_uuid"
                                                            checked={!!autoBlockUuid}
                                                            onChange={(event: any) => setAutoBlockUuid(Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="auto_block_uuid">{t('Automatic Block UUID')}</Label>
                                                        <TooltipCustom
                                                            title={t("If all the conditions set for the day are met, the UUID is automatically blocked")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={4} className="mb-3">
                                                    <div className="mb-0">
                                                        <Label className="form-label" for="exceed_request_per_hour">{t('Daily request count (Exceeds)')}</Label>
                                                        <TooltipCustom
                                                            title={t("If the number of requests exceeds the specified limit, it automatically becomes a target for blocking")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                        <Input className="form-control text-start"
                                                            type="text"
                                                            id="exceed_request_per_hour"
                                                            name="exceed_request_per_hour"
                                                            value={formatNumberWithCommas(exceedRequestPerHour)}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, '');
                                                                const s = Number(onlyNumbers);
                                                                setExceedRequestPerHour(s)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={4} className="mb-0">
                                                    <div className="mb-0">
                                                        <Label className="form-label" for="below_click_per_hour">{t('Daily click count (Below)')}</Label>
                                                        <TooltipCustom
                                                            title={t("If the number of clicks is less than the specified amount, it automatically becomes a target for blocking")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                        <Input className="form-control text-start"
                                                            type="text"
                                                            id="below_click_per_hour"
                                                            name="below_click_per_hour"
                                                            value={formatNumberWithCommas(belowClickPerHour)}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, '');
                                                                const s = Number(onlyNumbers);
                                                                setBelowClickPerHour(s)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="border border-dashed border-primary p-4 mb-3 mt-4 position-relative" style={{ borderRadius: '8px' }}>
                                                <span className="h6 position-absolute text-primary mb-0" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -50%)',
                                                    backgroundColor: 'var(--vz-card-bg)',
                                                    padding: '0 5px',
                                                }}
                                                >{t('Except Keyword')}</span>
                                                <Col xxl={12}>
                                                    <div style={{ minHeight: '100px' }}>
                                                        <label className="form-label">
                                                            {t("Except Keyword")}
                                                        </label>
                                                        <TooltipCustom
                                                            title={t("If specific keywords is included in referral url, we show ad but it's not working 'Click Option', 'Size Option', only working normally in any cases.")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                        <Hashtag
                                                            name="tags"
                                                            placeholder={`${t("Except Keyword")}...`}
                                                            suggestions={[]}
                                                            initialValue={keywords || []}
                                                            onChangeTag={(event: any) => {
                                                                setKeywords((prev) => event);
                                                            }}
                                                            idSelected="hashtags-CampaignFormMulti"
                                                            maxHashtags={2000}
                                                            maxHeight="150px"
                                                        />
                                                    </div>
                                                </Col>
                                            </div>

                                            <Col sm={12} className="hstack gap-1 justify-content-end mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={handleUpdateExceptKeywords}
                                                        disabled={isLoading || isExceptKeywordLoading}
                                                        style={{ width: '130px' }}
                                                    >
                                                        {isLoading ? (<Spinner size="sm"></Spinner>) : t('Button Update')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Col>
                                        <Col sm={0} md={2}></Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment >
    );
};

export default MonitorConfiguration;