import { ICredential } from "api/types/_credential";
import { IMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postCredential, putCredential } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ICredential | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const FormApi = ({
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                tracking_code: values?.tracking_code,
                api_access_key: values?.api_access_key,
                api_secret_key: values?.api_secret_key,
            };
            const response: any = detail?.id ? await putCredential(detail?.id, data) : await postCredential(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        tracking_code: Yup.string().required(`${t("This is required")}`),
        api_access_key: Yup.string().required(`${t("This is required")}`),
        api_secret_key: Yup.string().required(`${t("This is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            tracking_code: '',
            api_access_key: '',
            api_secret_key: ''
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_tracking_code = valueDefault?.tracking_code;
        const v_api_access_key = valueDefault?.api_access_key;
        const v_api_secret_key = valueDefault?.api_secret_key;

        formik.setFieldValue("tracking_code", v_tracking_code);
        formik.setFieldValue("api_access_key", v_api_access_key);
        formik.setFieldValue("api_secret_key", v_api_secret_key);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Tracking Code")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="tracking_code"
                                                        name="tracking_code"
                                                        autocomplete={false}
                                                        value={formik?.values?.tracking_code}
                                                        placeholder={`${t("Tracking Code")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("tracking_code", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.tracking_code && formik.errors.tracking_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.tracking_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Secret Key")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        id="api_secret_key"
                                                        name="api_secret_key"
                                                        autocomplete={false}
                                                        rows={3}
                                                        value={formik?.values?.api_secret_key}
                                                        placeholder={`${t("Secret Key")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("api_secret_key", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.api_secret_key && formik.errors.api_secret_key ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.api_secret_key}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Access Key")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        id="api_access_key"
                                                        name="api_access_key"
                                                        autocomplete={false}
                                                        rows={5}
                                                        value={formik?.values?.api_access_key}
                                                        placeholder={`${t("Access Key")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("api_access_key", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.api_access_key && formik.errors.api_access_key ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.api_access_key}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Credential") : t("Button Create Credential")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormApi;
