import { formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";


const ChartLine = ({
  categories = [],
  series = [],
  className = '',
  titles = ['', ''],
  enabledDataLabels = false,
}: any) => {
  var options = {
    chart: {
      height: 350,
      toolbar: 'false',
      type: 'line',
      // group: 'social',
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: enabledDataLabels
    },
    stroke: {
      curve: 'straight', // straight
      width: [2, 2, 2, 2],
      dashArray: [0, 0, 0, 0]
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      type: 'category',
      categories: categories,
      labels: {
        show: true,
        // formatter: function (val: any) {
        //   return moment(val).format('MM-DD');
        // },
        style: {
          fontSize: "12px",
          fontWeight: 500,
          colors: [],
        },
      },
    },
    colors: ['#009ef7', '#50cd89', '#546E7A', '#FF9800'],
    yaxis: [{
      // max: 60000,
      min: 0,
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#009ef7'
      },
      labels: {
        formatter: function (val: any) {
          return formatNumberWithCommas(Math.round(val));
        },
        style: {
          colors: '#009ef7',
        }
      },
      title: {
        text: titles?.[0] || '',
        style: {
          color: '#009ef7',
        }
      },
      tooltip: {
        enabled: false
      }
    },
    {
      min: 0,
      tickAmount: 5,
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#50cd89'
      },
      labels: {
        formatter: function (val: any) {
          return formatNumberWithCommas(Math.round(val));
        },
        style: {
          colors: '#50cd89',
        }
      },
      opposite: true,
      title: {
        text: titles?.[1] || '',
        style: {
          color: '#50cd89',
        }
      },
      tooltip: {
        enabled: false
      }
    },
    ],
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        height="350"
        className={`apex-charts ${className}`}
      />
    </React.Fragment>
  );
};

export default ChartLine;