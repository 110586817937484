import { IInventory } from 'api/types/_inventory';
import { IMedia } from 'api/types/_media';
import { ITracking } from 'api/types/_tracking';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CopyWrapper from 'components/Common/CopyWrapper';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownInventory from 'components/Common/DropdownInventory';
import DropdownMedia from 'components/Common/DropdownMedia';
import DropdownOption from 'components/Common/DropdownOption';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_PLATFORM_LOG_OPTIONS, AD_TYPE_INVENTORY_LOG_OPTIONS, AD_TYPE_INVENTORY_OPTIONS, AD_TYPE_OPTIONS, BOT_OPTIONS, BROWSER_OPTIONS, DEVICE_OPTIONS, IS_OPTIONS, OS_OPTIONS, PLATFORM_LOG_OPTIONS, POSITION_INVENTORY_OPTIONS, REFERRAL_TYPE_OPTIONS, TYPE_NEWS_OPTIONS, VERSION_OPTIONS } from 'helpers/constans';
import { formatLinkHTTPS, renderUrlSearchLookingUpByIP } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { blockAdsMultiplesUUID, blockMultiplesUUID, getAllInventories, getAllMedias, getTrackingLogsByNormal, getTrackingSummariesLogs } from "../../../store/thunks";
import TableTracking from '../TableTracking';
import SVGImageDefault from 'components/Images/SVGImageDefault';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'created_at';
const ORDER_BY_DEFAULT = 'DESC';

const TrackingLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { durationResponses, setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);

  const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_PLATFORM_LOG_OPTIONS_LANG = AD_PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_TYPE_OPTIONS_LANG = AD_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const DEVICE_OPTIONS_LANG = DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BOT_OPTIONS_LANG = BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BROWSER_OPTIONS_LANG = BROWSER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const OS_OPTIONS_LANG = OS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const IS_OPTIONS_LANG = IS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const REFERRAL_TYPE_OPTIONS_LANG = REFERRAL_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const VERSION_OPTIONS_LANG = VERSION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    domain: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    reference_link: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    og_url: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    uuid: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    platform: withDefault(StringParam, ''),
    ad_platform: withDefault(StringParam, ''),
    ad_type: withDefault(StringParam, ''),
    inventory_ad_type: withDefault(StringParam, ''),
    device: withDefault(StringParam, ''),
    browser: withDefault(StringParam, ''),
    os: withDefault(StringParam, ''),
    inventory_code: withDefault(StringParam, ''),
    bot: withDefault(StringParam, '0'),
    referral_type: withDefault(StringParam, ''),
    script_version: withDefault(StringParam, ''),
    banner_size: withDefault(StringParam, ''),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(query?.reference_link || "");
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.og_url || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [uuidSearch, setUuidSearch] = useState<string>(query?.uuid || "");

  const [bannerSizeSearch, setBannerSizeSearch] = useState<string>(query?.banner_size || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [listInventory, setListInventory] = useState<IInventory[]>([]);

  const [inventorySearch, setInventorySearch] = useState<Option | null>({
    label: listInventory?.filter((item) => String(item?.code || '') === String(query?.inventory_code || ''))[0]?.ad_sub_id || t('All Inventory'),
    value: `${listInventory?.filter((item) => String(item?.code || '') === String(query?.inventory_code || ''))[0]?.code || ''}`
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(PLATFORM_LOG_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.platform))[0]);

  const [adPlatformSearch, setAdPlatformSearch] = useState<Option | null>(AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_platform))[0]);

  const [adTypeSearch, setAdTypeSearch] = useState<Option | null>(AD_TYPE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_type))[0]);

  const [adTypeInventorySearch, setAdTypeInventorySearch] = useState<Option | null>(AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.inventory_ad_type))[0]);

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(DEVICE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.device))[0]);

  const [botSearch, setBotSearch] = useState<Option | null>(BOT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.bot))[0]);

  const [browserSearch, setBrowserSearch] = useState<Option | null>(BROWSER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.browser))[0]);

  const [referralTypeSearch, setReferralTypeSearch] = useState<Option | null>(REFERRAL_TYPE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.referral_type))[0]);

  const [versionSearch, setVersionSearch] = useState<Option | null>(VERSION_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.script_version))[0]);

  const [osSearch, setOsSearch] = useState<Option | null>(OS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.os))[0]);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [previewTrackingLog, setPreviewTrackingLog] = useState<ITracking | null>(null);

  // Inside your component

  const [trackingLogs, setTrackingLogs] = useState<{ list: ITracking[], total: number }>({ list: [], total: 0 });

  const [isTrackingLogLoading, setIsTrackingLogLoading] = useState<boolean>(false);

  const [summariesLogs, setSummariesLogs] = useState<{ pv: number, uv: number, impression: number, click: number, uuid_uv: number }>({ uv: 0, pv: 0, impression: 0, click: 0, uuid_uv: 0 });
  const [isSummariesLogLoading, setIsSummariesLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    try {
      setIsTrackingLogLoading((prev) => true);
      if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
        setQuery({
          ...query,
          page: 1
        });
        return;
      }
      const res: any = await getTrackingLogsByNormal(query);
      setTrackingLogs((prev: any) => ({
        list: Number(query?.page) === 1 ? (res?.data?.list || []) : (prev?.list || []).concat(res?.data?.list || []),
        total: res?.data?.pagination?.total || 0,
      }));
      setIsTrackingLogLoading((prev) => false);
      setDurationResponses([{
        name: 'Ads Log',
        time: res?.data?.pagination?.duration || 0
      }]);
    } catch (error: any) {
      setIsTrackingLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleQuerySummariesData = async () => {
    try {
      isFirstLoadingPageRef.current = false;
      setIsSummariesLogLoading((prev) => true);
      const res: any = await getTrackingSummariesLogs(query);
      setSummariesLogs((prev: any) => ({
        uv: res?.data?.uv || 0,
        pv: res?.data?.pv || 0,
        impression: res?.data?.impression || 0,
        click: res?.data?.click || 0,
        uuid_uv: res?.data?.uuid_uv || 0,
      }));
      setIsSummariesLogLoading((prev) => false);
      setDurationResponses([{
        name: 'Summaries Log',
        time: res?.duration || 0
      }]);
    } catch (error: any) {
      setIsSummariesLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    handleQueryData();
    (query?.page === 1) && handleQuerySummariesData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      title: titleSearch || "",
      reference_link: encodeURIComponent(referenceLinkSearch) || "",
      ip: ipSearch || "",
      og_url: encodeURIComponent(urlSearch) || '',
      keyword: keywordSearch || "",
      uuid: uuidSearch || "",
      banner_size: bannerSizeSearch || "",
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      platform: platformSearch?.value || '',
      ad_platform: adPlatformSearch?.value || '',
      ad_type: adTypeSearch?.value || '',
      inventory_ad_type: adTypeInventorySearch?.value || '',
      device: deviceSearch?.value || '',
      bot: botSearch?.value ?? '',
      browser: browserSearch?.value ?? '',
      os: osSearch?.value ?? '',
      referral_type: referralTypeSearch?.value ?? '',
      script_version: versionSearch?.value ?? '',
      inventory_code: inventorySearch?.value || '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        pv: 0,
        uv: 0,
        impression: 0,
        click: 0,
        uuid_uv: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      title: '',
      reference_link: '',
      ip: '',
      og_url: '',
      keyword: '',
      uuid: '',
      banner_size: '',
      domain: '',
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      platform: '',
      ad_platform: '',
      ad_type: '',
      inventory_ad_type: '',
      device: '',
      bot: '0',
      browser: '',
      referral_type: '',
      script_version: '',
      os: '',
      inventory_code: '',
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        pv: 0,
        uv: 0,
        impression: 0,
        click: 0,
        uuid_uv: 0,
      }));
    }
    setQuery(queryNew, "push");
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setUuidSearch((prev) => "");
    setBannerSizeSearch((prev) => "");
    setTitleSearch((_prev) => "");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setPlatformSearch(null);
    setAdPlatformSearch(null);
    setAdTypeSearch(null);
    setDeviceSearch(null);
    setBotSearch(BOT_OPTIONS_LANG[0]);
    setBrowserSearch(null);
    setOsSearch(null);
    setReferralTypeSearch(null);
    setVersionSearch(null);
    setInventorySearch({ label: t('All Inventory'), value: '' });
    setAdTypeInventorySearch(null);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  function onClosePreviewTrackingLogClick() {
    setPreviewTrackingLog((_prev) => null);
  }

  const handlePreviewTrackingLog = async (TrackingLog: ITracking | null = null) => {
    try {
      setPreviewTrackingLog((_prev) => TrackingLog);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  // Begin:: add Except UUID
  const [uuidLoadingAddBlock, setUUIDLoadingAddBlock] = useState<string>('');

  const handleAddBlock = async (itemAddBlock: { uuid: string, type: 'BLOCK' | 'EXCEPT', _id: string }) => {
    try {
      if (!itemAddBlock) {
        return;
      }
      setUUIDLoadingAddBlock((_prev) => `${itemAddBlock?._id}-${itemAddBlock?.type}`);
      const dataForm = {
        uuids: [itemAddBlock?.uuid || ""]
      };
      const response: any = itemAddBlock?.type === 'BLOCK' ? await blockMultiplesUUID({ ...dataForm, type: "manual" }) : await blockAdsMultiplesUUID({ ...dataForm, type: "manual" });
      setUUIDLoadingAddBlock((_prev) => '');
      if (response?.data) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setUUIDLoadingAddBlock((_prev) => '');
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: add Except UUID


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Log Type'),
        accessor: "ad_type",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '70px' }}>{AD_TYPE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Title Post'),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 320,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '250px', maxWidth: '300px' }}>
              <CopyWrapper contentCopy={item?.og_title}>
                <Link className='text-normal' to={item?.og_url} target="_blank">
                  {item?.og_title}
                </Link>
              </CopyWrapper>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '25px' : '45px' }}>{t('Url')}: </span>
                <CopyWrapper contentCopy={item?.domain_url}>
                  <Link className='text-normal' to={item?.domain_url} target="_blank">
                    {item?.domain_url}
                  </Link>
                </CopyWrapper>
              </div>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '100px' : '60px' }}>{t('WEB_reference_link')}: </span>
                <CopyWrapper contentCopy={item?.reference_link || ''}>
                  <Link className='text-normal ' to={item?.reference_link} target="_blank">
                    {item?.reference_link || ''}
                  </Link>
                </CopyWrapper>
              </div>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '100px' : '60px' }}>{t('WEB_uuid')}: </span>
                <CopyWrapper contentCopy={item?.uuid || ''}>
                  {item?.uuid || ''}
                </CopyWrapper>
              </div>
            </div>
          </>)
        },
      },
      {
        Header: t('Referral Type'),
        accessor: "referral_type",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '100px' }}>{REFERRAL_TYPE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: '130px' }}>
            <CopyWrapper contentCopy={cell?.value}>
              {cell?.value}
            </CopyWrapper>
          </div>)
        },
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const media = listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0] || null;
          return (<>
            <div className="cursor-pointer" style={{ minWidth: '150px' }}>
              {cell?.value && (<> <span>
                {media?.name || ''}
              </span>
                <br />
                {!!media && <Link className='text-normal' to={formatLinkHTTPS(media?.website || cell?.value)} target="_blank">
                  {media?.website || cell?.value}
                </Link>}
              </>
              )}
            </div>
          </>)
        },
      },
      {
        Header: t('Inventory'),
        accessor: "inventory_code",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const position = POSITION_INVENTORY_OPTIONS_LANG?.find((p) => p?.value === item?.position) || null;
          return (
            <>
              <div className="d-flex align-items-center" style={{ width: '190px' }}>
                <div className="flex-shrink-0 me-2 position-relative">
                  <SVGImageDefault style={{ height: '48px' }} />
                  {!!item?.is_main ? <></> : <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span>}
                </div>
                <div>
                  <div className="ms-2 fs-12 text-muted">{AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((op: any) => String(op?.value) === String(item?.ad_platform))?.label}</div>
                  <strong className="text-muted ms-2">{item?.width || ''} * {item?.height || ''}</strong>
                  <TooltipCustom
                    title={`(${t('Position')}, ${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Bottom')})`}
                    className="d-inline-block vertical-align-middle"
                  >
                    <div className="ms-2 fs-12 text-muted">({position?.label || ''}, {String(item?.margin_left ?? '').replace(/px$/, '')}, {String(item?.margin_right ?? '').replace(/px$/, '')}, {String(item?.margin_bottom ?? '').replace(/px$/, '')})</div>
                  </TooltipCustom>
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: t('WEB_banner_size'),
        accessor: "banner_size",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center" style={{ minWidth: '70px' }}>
                {cell?.value ? <span>{cell?.value}</span> : ''}
              </div>
            </>
          )
        },
      },
      {
        Header: t('Ad Info'),
        accessor: "ad_info",
        filterable: false,
        sortable: false,
        thWidth: 170,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-start fs-12" style={{ minWidth: '160px' }}>
              <div className="text-muted">{t('Tracking Code')}: <span>{item?.tracking_code || ''}</span></div>
              <div className="text-muted text-uppercase">{t('Ad ID')}: <span>{item?.ad_id || ''}</span></div>
              <div className="text-muted">{t('Sub ID')}: <span>{item?.sub_id || ''}</span></div>
            </div>
          )
        },
      },
      {
        Header: t('Ad Type'),
        accessor: "inventory_ad_type",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '100px' }}>
              <span>{AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('Device'),
        accessor: "device",
        filterable: true,
        sortable: false,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '70px' }}>{DEVICE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Platform'),
        accessor: "platform",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '90px' }}>{PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('OS'),
        accessor: "os",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('IP'),
        accessor: "ip",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => (
          <div style={{ minWidth: '120px' }}>
            <CopyWrapper contentCopy={cell?.value}>
              <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(cell?.value)} target="_blank">{cell?.value}</Link>
            </CopyWrapper>
          </div>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ width: '110px' }}>
              <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
                <TooltipCustom
                  title={t('Button Add Except UUID')}
                  id={`detail-log-${item?.id}`}
                >
                  <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleAddBlock({ uuid: item?.uuid, type: 'EXCEPT', _id: item?._id }) }}
                    >
                      {(`${item?._id}-EXCEPT` === uuidLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <i className="ri-forbid-2-fill align-bottom"></i>}
                    </Link>
                  </li>
                </TooltipCustom>
                <TooltipCustom
                  title={t('Button Add Block UUID')}
                  id={`detail-log-${item?.id}`}
                >
                  <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleAddBlock({ uuid: item?.uuid, type: 'BLOCK', _id: item?._id }) }}
                    >
                      {(`${item?._id}-BLOCK` === uuidLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <i className="ri-forbid-2-fill align-bottom"></i>}
                    </Link>
                  </li>
                </TooltipCustom>
                <TooltipCustom
                  title={t('Detail')}
                  id={`detail-log-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`detail-log-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handlePreviewTrackingLog(item) }}
                    >
                      <i className="ri-eye-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              </ul>
            </div>
          );
        },
      },
    ],
    [i18n?.language, JSON.stringify(listMedia), uuidLoadingAddBlock]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy, page: 1 });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resMediaLog, resInventories]: any = await Promise.all([getAllMedias(), getAllInventories()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
      const inventories = ([{ name: t('All Inventory'), id: '', website: '' } as any]).concat(resInventories?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => {
        const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
        return ({
          label: item?.name || t('All Media'),
          value: `${item?.id || ''}`,
        })
      });

      setListInventory((_prev) => inventories);
      setInventorySearch((_prev) => {
        const item = inventories?.find((item) => String(item?.code || '') === String(query?.inventory_code || ''));
        return ({
          label: item?.media?.name ? `[${!!item?.parent_code ? t('Main') : t('Sub')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})` : t('All Inventory'),
          value: `${item?.code || ''}`
        })
      });

    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch((_prev: any) =>
        PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => e.value === platformSearch?.value)[0]
      );
    }

    if (adPlatformSearch) {
      setAdPlatformSearch((_prev: any) =>
        AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((e: any) => e.value === adPlatformSearch?.value)[0]
      );
    }

    if (adTypeSearch) {
      setAdTypeSearch((_prev: any) =>
        AD_TYPE_OPTIONS_LANG?.filter((e: any) => e.value === adTypeSearch?.value)[0]
      );
    }

    if (deviceSearch) {
      setDeviceSearch((_prev: any) =>
        DEVICE_OPTIONS_LANG?.filter((e: any) => e.value === deviceSearch?.value)[0]
      );
    }

    if (botSearch) {
      setBotSearch((_prev: any) =>
        BOT_OPTIONS_LANG?.filter((e: any) => e.value === botSearch?.value)[0]
      );
    }

    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    if (inventorySearch) {
      setListInventory((_prev) => (_prev || [])?.map((item) => item?.code ? item : ({ ...item, name: t('All Inventory') })));
      setInventorySearch((_prev) => {
        const item = listInventory?.find((item) => String(item?.code || '') === String(inventorySearch?.value || ''));
        return ({
          label: item?.media?.name ? `[${!!item?.parent_code ? t('Main') : t('Sub')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})` : t('All Inventory'),
          value: `${item?.code || ''}`
        })
      });
    }

    if (browserSearch) {
      setBrowserSearch((_prev: any) =>
        BROWSER_OPTIONS_LANG?.filter((e: any) => e.value === browserSearch?.value)[0]
      );
    }

    if (osSearch) {
      setOsSearch((_prev: any) =>
        OS_OPTIONS_LANG?.filter((e: any) => e.value === osSearch?.value)[0]
      );
    }

    if (referralTypeSearch) {
      setReferralTypeSearch((_prev: any) =>
        REFERRAL_TYPE_OPTIONS_LANG?.filter((e: any) => e.value === referralTypeSearch?.value)[0]
      );
    }

    if (versionSearch) {
      setVersionSearch((_prev: any) =>
        VERSION_OPTIONS_LANG?.filter((e: any) => e.value === versionSearch?.value)[0]
      );
    }

    if (adTypeInventorySearch) {
      setAdTypeInventorySearch((_prev: any) =>
        AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((e: any) => e.value === adTypeInventorySearch?.value)[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Ads Log')} - ${t('Statistics')} | AdsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Ads Log')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.ADS_LOG, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isTrackingLogLoading && query?.page === 1) ? <Spinner className="text-primary" style={{ width: '23px', height: '23px' }}></Spinner> : (<CountUp
                              start={0}
                              end={trackingLogs?.total || 0}
                              duration={1}
                              className="text-primary h4"
                            />)}
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="border border-dashed border-end-0 border-start-0 card-body">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start ">
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mt-0 mt-md-3">
                        <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('PV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.pv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('UV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.uv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Impression')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.impression || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Click')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.click || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-2 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('UV (UUID)')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.uuid_uv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mb-sm-2 mt-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Log Type')} isShow={!!adTypeSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={AD_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Log Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adTypeSearch || null}
                                onChangeSelect={(e: any) => setAdTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Log Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <DropdownMedia
                                name="medias"
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Platform')} isShow={!!platformSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={PLATFORM_LOG_OPTIONS_LANG}
                                placeholder={`${t('Platform')}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={platformSearch || null}
                                onChangeSelect={(e: any) => setPlatformSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-0 mb-sm-2 mt-0 mt-sm-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('WEB_os')} isShow={!!osSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={OS_OPTIONS_LANG}
                                placeholder={`${t("WEB_os")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={osSearch || null}
                                onChangeSelect={(e: any) => setOsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All OS'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('WEB_browser')} isShow={!!browserSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={BROWSER_OPTIONS_LANG}
                                placeholder={`${t("WEB_browser")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={browserSearch || null}
                                onChangeSelect={(e: any) => setBrowserSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Browser'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Platform')} isShow={!!adPlatformSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={AD_PLATFORM_INVENTORY_OPTIONS_LANG}
                                placeholder={`${t("Ad Platform")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adPlatformSearch || null}
                                onChangeSelect={(e: any) => setAdPlatformSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Ad Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Device')} isShow={!!deviceSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={DEVICE_OPTIONS_LANG}
                                placeholder={`${t("Device")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={deviceSearch || null}
                                onChangeSelect={(e: any) => setDeviceSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Device'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-0 mt-sm-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('IP')}...`}
                                value={ipSearch}
                                onChange={(e) => setIpSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Referral Type')} isShow={!!referralTypeSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={REFERRAL_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Referral Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={referralTypeSearch || null}
                                onChangeSelect={(e: any) => setReferralTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Referral Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Inventory')} isShow={!!inventorySearch?.value}>
                              <DropdownInventory
                                name="inventories"
                                dataList={listInventory}
                                initialValue={inventorySearch}
                                onChangeSelect={(event: any) => {
                                  setInventorySearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2 '>
                            <LabelWrapper label={t('WEB_bot')} isShow={!!botSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={BOT_OPTIONS_LANG}
                                placeholder={`${t("WEB_bot")}...`}
                                className="search-filter-category-type dropdown-status-rounded "
                                classNamePrefix="name-prefix"
                                initialValue={botSearch || null}
                                onChangeSelect={(e: any) => setBotSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Type Bot'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('UUID')} isShow={!!uuidSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('UUID')}...`}
                                value={uuidSearch}
                                onChange={(e) => setUuidSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_banner_size')} isShow={!!bannerSizeSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('WEB_banner_size')}...`}
                                value={bannerSizeSearch}
                                onChange={(e) => setBannerSizeSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Type')} isShow={true}>
                              <DropdownOption
                                name="inventory_ad_type"
                                dataList={AD_TYPE_INVENTORY_OPTIONS_LANG}
                                placeholder={`${t('Ad Type')}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adTypeInventorySearch || null}
                                onChangeSelect={(e: any) => setAdTypeInventorySearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Version')} isShow={!!versionSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={VERSION_OPTIONS_LANG}
                                placeholder={`${t("Version")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={versionSearch || null}
                                onChangeSelect={(e: any) => setVersionSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Version'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Keyword')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Title Post')} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_reference_link')} isShow={!!referenceLinkSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('WEB_reference_link')}...`}
                                value={referenceLinkSearch}
                                onChange={(e) => setReferenceLinkSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Url')} isShow={!!urlSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Url')}...`}
                                value={urlSearch}
                                onChange={(e) => setUrlSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={12} className="hstack gap-1 justify-content-center justify-content-md-end mt-4 mt-md-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isTrackingLogLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody className="pt-0 px-0">
                    <div>
                      <InfiniteScroll
                        dataLength={trackingLogs?.list?.length || 0}
                        next={handleLoadMore}
                        scrollableTarget='scrollableDiv'
                        hasMore={trackingLogs && trackingLogs?.list?.length < trackingLogs?.total ? true : false}
                        loader={''} // loader={<LoadingListNotify />}
                        scrollThreshold={'50%'}
                      >
                        <div className="mx-3 my-4 mt-3">
                          <TableContainer
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="align-middle table-bordered-dashed sticky-table-tracking-log"
                            theadClass="table-light text-muted"
                            columns={columns}
                            data={trackingLogs?.list?.length ? trackingLogs?.list : []}
                            customPageSize={query.limit}
                            customPageIndex={query.page - 1}
                            totalRecords={trackingLogs?.total}
                            customPageCount={1}
                            handleChangePage={handleChangePage}
                            manualSorting={true}
                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                            handleChangeSorting={handleChangeSorting}
                            isLoading={isTrackingLogLoading}
                            isShowPagination={false}
                            isShowLoadingBottom={query.page > 1}
                            isScrollToTop={false}
                          />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={!!previewTrackingLog} centered={true} size="xl" scrollable={true} toggle={onClosePreviewTrackingLogClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewTrackingLogClick}>
            {t('Detail Tracking Log')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <TableTracking data={previewTrackingLog} />
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewTrackingLogClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
      </div >
    </React.Fragment >
  );
};

export default TrackingLog;
