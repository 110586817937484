import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { downloadExcelSettlementReports, getAllAgencies, getAllMedias, reportSettlements as onGetReportSettlements } from "../../../store/thunks";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import SVGImageDefault from "components/Images/SVGImageDefault";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import { IMedia } from "api/types/_media";
import { IAgency } from "api/types/_agency";
import { Option } from "api/types/_public";
import DropdownAgency from "components/Common/DropdownAgency";
import DropdownMedia from "components/Common/DropdownMedia";


registerLocale('en', en);
registerLocale('ko', ko);

const ReportSettlement = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        date: withDefault(StringParam, moment(new Date()).format("yyyy-MM")),
        media_id: withDefault(StringParam, ''),
        agency_id: withDefault(StringParam, ''),
        is_export: withDefault(NumberParam, 0),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            settlements: state.settlements,
            isSettlementSuccess: state.isSettlementSuccess,
            isSettlementLoading: state.isSettlementLoading,
            error: state.error,
        })
    );

    const { settlements, isSettlementLoading } = useSelector(ManagementProperties);

    const [dateSearch, setDateSearch] = useState<string>(query?.date || "");

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.find((item) => String(item?.id || '') === String(query?.media_id || ''))?.name || t('All Media'),
        value: `${listMedia?.find((item) => String(item?.id || '') === String(query?.media_id || ''))?.id || ''}`
    });

    const [listAgency, setListAgency] = useState<IAgency[]>([]);
    const [agencySearch, setAgencySearch] = useState<Option | null>({
        label: listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.name || t('All Agency'),
        value: `${listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.id || ''}`
    });


    const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetReportSettlements(query));
    }, [JSON.stringify(query)]);

    useEffect(() => {
        document.title = `${t("Report Settlement")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const searchData = () => {
        setQuery({
            ...query,
            date: dateSearch,
            media_id: mediaSearch?.value || '',
            agency_id: agencySearch?.value || '',
            is_export: 0,
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            date: moment(new Date()).format("yyyy-MM"),
            media_id: '',
            agency_id: '',
            time_request: + new Date(),
            is_export: 0,
        };
        setQuery(queryNew, "push");
        setMediaSearch({ label: t('All Media'), value: '' });
        setAgencySearch({ label: t('All Agency'), value: '' });
        setDateSearch(moment(new Date()).format("yyyy-MM"));
    };
    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                thWidth: 160,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media: any = item?.media || null;
                    return (<>
                        {(!!item?.text_row) ? <strong>{item?.text_row}</strong> : (<div className="d-flex align-items-center" style={{ minWidth: '160px' }}>
                            <div className="me-2">
                                {!!media?.logo_url ? (
                                    <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                    <SVGImageDefault style={{ height: '48px' }} />
                                )}
                            </div>
                            <div>
                                <div>
                                    {media?.name || ''}
                                </div>
                                <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website || cell?.value)} target="_blank">
                                    {media?.website || ''}
                                </Link>
                            </div>
                        </div>)}
                    </>)
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 160,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '140px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Media Revenue'),
                accessor: "media_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 140,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Agency'),
                accessor: "agency",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{cell?.value?.name || ''}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Agency Revenue'),
                accessor: "agency_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (value: Date | null) => {
        setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM") : '');
    }

    const handleDownloadExcel = async () => {
        try {
            setIsLoadingExportFile((_prev) => true);
            const response: any = await downloadExcelSettlementReports({ ...query, is_export: 1 });
            if (response?.data) {
                setIsLoadingExportFile((_prev) => false);
                const link = document.createElement('a');
                link.href = response?.data;
                link.download = 'campaign';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setIsLoadingExportFile((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingExportFile((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resMedia, resAgency]: any = await Promise.all([getAllMedias(), getAllAgencies()]);
                const list_m = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
                const media = list_m?.find((item) => String(item?.id || '') === String(query?.media_id || '')) || null;
                setListMedia((_prev) => list_m);
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: media?.id || '',
                }));

                const list_a = ([{ name: t('All Agency'), id: '' } as any]).concat(resAgency?.data || []);
                const agency = list_a?.find((item) => String(item?.id || '') === String(query?.agency_id || '')) || null;
                setListAgency((_prev) => list_a);
                setAgencySearch((_prev) => ({
                    label: agency?.name || t('All Agency'),
                    value: agency?.id || '',
                }));
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {

        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        if (agencySearch) {
            setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
            (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        document.title = `${t("Settlement Report")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Settlement Report')} pageTitle={t('Reports')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_SETTLEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={settlements?.list.length || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Agency')} isShow={!!agencySearch?.value}>
                                                    <DropdownAgency
                                                        name="agency"
                                                        dataList={listAgency}
                                                        initialValue={agencySearch}
                                                        onChangeSelect={(event: any) => {
                                                            setAgencySearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePicker
                                                        className="form-control search"
                                                        placeholderText={`${t('Date Car')}...`}
                                                        value={dateSearch || ''}
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        showFourColumnMonthYearPicker
                                                        dateFormat="yyyy-MM"
                                                        isClearable={true}
                                                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                        onChange={handleChangePicker}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isSettlementLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary me-2"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={handleDownloadExcel}
                                                    disabled={isLoadingExportFile}
                                                >
                                                    {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                                                    <span className="ms-1">{t('Button Download Excel')}</span>
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={settlements?.list?.length ? (
                                                [
                                                    {
                                                        ...settlements?.total,
                                                        impression: settlements?.total?.impression,
                                                        media_revenue: settlements?.total?.media_revenue,
                                                        agency_revenue: settlements?.total?.agency_revenue,
                                                        text_row: t('Sum')
                                                    },
                                                    ...settlements?.list]
                                            ) : []}
                                            customPageSize={100}
                                            customPageIndex={1}
                                            totalRecords={settlements?.list.length}
                                            customPageCount={Math.ceil(Number(settlements?.list.length) / 100)}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query?.sort_by, order_by: query?.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isSettlementLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportSettlement;
