import { IInventory } from "api/types/_inventory";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownMedia from "components/Common/DropdownMedia";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_TYPE_INVENTORY_OPTIONS, POSITION_INVENTORY_OPTIONS, STATUS_INVENTORY_OPTIONS, TYPE_ADS_DEVICE_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteInventory, getAllAgencies, getAllInventories, getAllMedias, getInventories as onGetInventories, updateConfigInventory, updateMemoInventory } from "../../../store/thunks";
import FormInventory from "./FormInventory";
import { IAgency } from "api/types/_agency";
import DropdownAgency from "components/Common/DropdownAgency";
import DropdownOption from "components/Common/DropdownOption";
import TableInventory from "components/Common/TableInventory";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementInventory = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_INVENTORY_OPTIONS_LANG = STATUS_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const DEVICE_OPTIONS_LANG = TYPE_ADS_DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_INVENTORY_OPTIONS_LANG = [{ value: '1', label: 'Main' }, { value: '2', label: 'Sub' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_SETTING_OPTIONS_LANG = [{ value: '1', label: 'Applied' }, { value: '0', label: 'Not Applied' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
        media_code: withDefault(StringParam, ''),
        device: withDefault(StringParam, ''),
        agency_code: withDefault(StringParam, ''),
        inventory_type: withDefault(StringParam, ''),
        fill_rate: withDefault(StringParam, ''),
        flg_banner_size: withDefault(StringParam, ''),
        flg_click: withDefault(StringParam, ''),
        is_block_referral: withDefault(StringParam, ''),
        is_except_keyword: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            inventories: state.inventories,
            isInventorySuccess: state.isInventorySuccess,
            isInventoryLoading: state.isInventoryLoading,
            error: state.error,
        })
    );

    const { inventories, isInventoryLoading } = useSelector(ManagementProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.code || '') === String(query?.media_code || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.code || '') === String(query?.media_code || ''))[0]?.code || ''}`
    });

    const [listAgency, setListAgency] = useState<IAgency[]>([]);
    const [agencySearch, setAgencySearch] = useState<Option | null>({
        label: listAgency?.find((item) => String(item?.code || '') === String(query?.agency_code || ''))?.name || t('All Agency'),
        value: `${listAgency?.find((item) => String(item?.code || '') === String(query?.agency_code || ''))?.code || ''}`
    });

    const [listInventory, setListInventory] = useState<IInventory[]>([]);

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

    const [deviceSearch, setDeviceSearch] = useState<Option | null>(DEVICE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.device))[0]);

    const [typeSearch, setTypeSearch] = useState<Option | null>(TYPE_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.inventory_type))[0]);

    const [fillRateSearch, setFillRateSearch] = useState<Option | null>(STATUS_SETTING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.fill_rate))[0]);
    const [flgBannerSizeSearch, setFlgBannerSizeSearch] = useState<Option | null>(STATUS_SETTING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.flg_banner_size))[0]);
    const [flgClickSearch, setFlgClickSearch] = useState<Option | null>(STATUS_SETTING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.flg_click))[0]);
    const [isBlockReferralSearch, setIsBlockReferralSearch] = useState<Option | null>(STATUS_SETTING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_block_referral))[0]);
    const [isExceptKeywordSearch, setIsExceptKeywordSearch] = useState<Option | null>(STATUS_SETTING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_except_keyword))[0]);

    const [inventoryDetail, setInventoryDetail] = useState<IInventory | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetInventories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            status: statusSearch?.value || '',
            device: deviceSearch?.value || '',
            inventory_type: typeSearch?.value || '',
            media_code: mediaSearch?.value || '',
            agency_code: agencySearch?.value || '',
            fill_rate: fillRateSearch?.value || '',
            flg_banner_size: flgBannerSizeSearch?.value || '',
            flg_click: flgClickSearch?.value || '',
            is_block_referral: isBlockReferralSearch?.value || '',
            is_except_keyword: isExceptKeywordSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            status: '',
            device: '',
            media_code: '',
            agency_code: '',
            inventory_type: '',
            fill_rate: '',
            flg_banner_size: '',
            flg_click: '',
            is_block_referral: '',
            is_except_keyword: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setStatusSearch({ label: t('All Status'), value: '' });
        setMediaSearch({ label: t('All Media'), value: '' });
        setDeviceSearch({ label: t('All Device'), value: '' });
        setAgencySearch({ label: t('All Agency'), value: '' });
        setTypeSearch({ label: t('All Type'), value: '' });

        setFillRateSearch({ label: t('All Setting'), value: '' });
        setFlgBannerSizeSearch({ label: t('All Setting'), value: '' });
        setFlgClickSearch({ label: t('All Setting'), value: '' });
        setIsBlockReferralSearch({ label: t('All Setting'), value: '' });
        setIsExceptKeywordSearch({ label: t('All Setting'), value: '' });

    };

    const handleRefreshAllInventories = async () => {
        try {
            const [resInventory]: any = await Promise.all([getAllInventories()]);
            const list_i = (resInventory?.data || []);
            setListInventory((_prev) => list_i);
        } catch (error: any) {
            return error;
        }
    }

    const handleRefresh = () => {
        try {
            setInventoryDetail((_prev) => null);
            resetData();
            handleRefreshAllInventories();
        } catch (error: any) {
            return error;
        }
    }

    // Begin:: update config inventory
    const [itemUpdate, setItemUpdate] = useState<{ id: number, data: { flg_click?: number, flg_banner_size?: number, fill_rate?: number, is_block_referral?: number, is_except_keyword?: number } } | null>(null);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const handleShowConfirmUpdateConfig = (item: { id: number, data: { flg_click?: number, flg_banner_size?: number, fill_rate?: number, is_block_referral?: number, is_except_keyword?: number } } | null) => {
        setItemUpdate(item);
    };
    const handleOnConfirmUpdate = async () => {
        try {
            if (!itemUpdate) {
                return;
            }
            setIsLoadingUpdate((_prev) => true);
            const response: any = await updateConfigInventory(itemUpdate?.id, itemUpdate?.data);
            setIsLoadingUpdate((_prev) => false);
            if (response?.code === 200) {
                setItemUpdate((_prev) => null);
                dispatch(onGetInventories(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdate((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update config inventory

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: true,
                thWidth: 60,
                Cell: (cell: any) => (
                    <div style={{ width: '60px' }}></div>
                ),
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: false,
                sortable: false,
                thWidth: 250,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = cell?.value;
                    return (<></>)
                },
            },
            {
                Header: t('Inventory'),
                accessor: "attributes",
                filterable: false,
                sortable: false,
                thWidth: 200,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '190px' }}>

                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Fill-Rate'),
                accessor: "fill_rate",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 120,
                description: t('Ad is displayed once every hour'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '110px' }} className="position-relative">

                        </div>
                    )
                },
            },
            {
                Header: t('Click Option'),
                accessor: "configs.flg_click",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                description: t('When incoming from an external URL, click on the advertisement'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Size Option'),
                accessor: "configs.flg_banner_size",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                description: t('When incoming from an external URL, change the banner size'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }} className="position-relative">

                        </div>
                    )
                },
            },
            {
                Header: t('Block Referral'),
                accessor: "is_block_referral",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 140,
                description: t("If the referral is empty or main, it's now shown ad"),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '130px' }} className="position-relative">

                        </div>
                    )
                },
            },
            {
                Header: t('Block Keyword'),
                accessor: "is_except_keyword",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 140,
                description: t("If specific keywords is included in referral url, we show ad but it's not working 'Click Option', 'Size Option', only working normally in any cases."),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '130px' }} className="position-relative">

                        </div>
                    )
                },
            },
            {
                Header: t('Tracking Code'),
                accessor: "ad_tracking_code",
                filterable: false,
                sortable: false,
                thWidth: 130,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '120px' }}>

                    </div>
                ),
            },
            {
                Header: t('Sub ID'),
                accessor: "ad_sub_id",
                filterable: false,
                sortable: false,
                thWidth: 150,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '140px' }} >
                        </div>
                    </>
                ),
            },
            {
                Header: t('Ad ID'),
                accessor: "ad_id",
                filterable: false,
                sortable: false,
                thWidth: 110,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '100px' }}>

                        </div>
                    </>
                ),
            },
            {
                Header: t('Script Url'),
                accessor: "script_url",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '130px' }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Version'),
                accessor: "version",
                filterable: false,
                sortable: false,
                thWidth: 150,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-center">

                        </div>
                    )
                },
            },
            {
                Header: t('Ad Type'),
                accessor: "ad_type",
                filterable: false,
                sortable: false,
                thWidth: 150,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '100px' }}>
                            <span>{AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thWidth: 85,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '75px' }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Agency'),
                accessor: "agency",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => (
                    <>
                        <div className="text-center" style={{ minWidth: '80px' }}></div>
                    </>
                ),
            },
            {
                Header: t('Memo'),
                accessor: "memo",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-start d-flex justify-content-end align-items-center w-100 position-relative" style={{ minWidth: '130px', maxWidth: '200px' }}

                        >

                        </div>
                    )
                },
            },
            {
                Header: t('Additional Ad'),
                accessor: "hidden_ads_count",
                filterable: false,
                sortable: false,
                thWidth: 140,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const configs = item?.configs || null;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '120px' }}></div>
                        </>
                    );
                },
            },
            {
                Header: t('Target CTR'),
                accessor: "target_ctr",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const configs = item?.configs || null;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '80px' }}></div>
                        </>
                    );
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">

                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IInventory) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteInventory(idDelete);
            if (response?.code === 200) {
                dispatch(onGetInventories(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
                handleRefreshAllInventories();
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleCreateSubInventoryFrom = (item: IInventory) => {
        const cloneInventory: any = structuredClone(item);
        if (cloneInventory?.id) {
            delete cloneInventory.id;
        }
        const v: IInventory = {
            ...cloneInventory,
            parent_code: item?.id,
            ad_platform: 2,
            ad_tracking_code: '',
            ad_sub_id: '',
            ad_id: '',
            configs: {
                ...cloneInventory?.configs,
                target_ctr: 15,
                hidden_ads_count: 3,
            }
        } as IInventory;
        setInventoryDetail((prev) => v);
    }

    // Begin:: update memo
    const [itemUpdateMemo, setItemUpdateMemo] = useState<{ id: number, memo: string } | null>(null);
    const [isLoadingUpdateMemo, setIsLoadingUpdateMemo] = useState<boolean>(false);

    const handleUpdateMemo = async () => {
        try {
            if (!itemUpdateMemo) {
                return;
            }
            setIsLoadingUpdateMemo((_prev) => true);
            const response: any = await updateMemoInventory(itemUpdateMemo?.id, { memo: itemUpdateMemo?.memo });
            setIsLoadingUpdateMemo((_prev) => false);
            if (response?.code === 200) {
                setItemUpdateMemo((_prev) => null);
                dispatch(onGetInventories(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateMemo((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update memo

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resMedia, resAgency, resInventory]: any = await Promise.all([getAllMedias(), getAllAgencies(), getAllInventories()]);
                const list = ([{ name: t('All Media'), id: '', website: '', code: '' } as any]).concat(resMedia?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => ({
                    label: list?.filter((item) => String(item?.code || '') === String(query?.media_code || ''))[0]?.name || t('All Media'),
                    value: `${list?.filter((item) => String(item?.code || '') === String(query?.media_code || ''))[0]?.code || ''}`
                }));

                const list_a = ([{ name: t('All Agency'), id: '', code: '' } as any]).concat(resAgency?.data || []);
                const agency = list_a?.find((item) => String(item?.code || '') === String(query?.agency_code || '')) || null;
                setListAgency((_prev) => list_a);
                setAgencySearch((_prev) => ({
                    label: agency?.name || t('All Agency'),
                    value: agency?.code || '',
                }));

                const list_i = (resInventory?.data || []);
                setListInventory((_prev) => list_i);

            } catch (error: any) {
                return error;
            }
        };

        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }

        if (agencySearch) {
            setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
            (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
        }

        document.title = `${t("Inventory Management")} - ${t("Advertising Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Inventory Management')} pageTitle={t('Advertising Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_INVENTORY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={inventories?.count?.total_inventories || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setInventoryDetail((_prev) => ({} as IInventory))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Inventory')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-3">
                                        <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mb-3" style={{ maxWidth: '100%' }}>
                                            <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary border-0">
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Fill-Rate')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isInventoryLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={inventories?.count?.fill_rate_count || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary border-0">
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Click Option')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isInventoryLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={inventories?.count?.click_count || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary border-0">
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Size Option')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isInventoryLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={inventories?.count?.banner_size_count || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary border-0">
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Block Referral')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isInventoryLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={inventories?.count?.block_referral_count || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary border-0">
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Block Keyword')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isInventoryLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={inventories?.count?.except_keyword_count || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Agency')} isShow={!!agencySearch?.value}>
                                                    <DropdownAgency
                                                        name="agency"
                                                        typeValue="code"
                                                        dataList={listAgency}
                                                        initialValue={agencySearch}
                                                        onChangeSelect={(event: any) => {
                                                            setAgencySearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        typeValue="code"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Type Inventory')} isShow={true}>
                                                    <DropdownOption
                                                        name="inventory_type"
                                                        dataList={TYPE_INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Type Inventory")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={typeSearch || null}
                                                        onChangeSelect={(e: any) => setTypeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Type'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={0} lg={0} xl={4} xxl={4} className="m-0 d-none d-xl-block"></Col>

                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Fill-Rate')} isShow={true}>
                                                    <DropdownOption
                                                        name="fill_rate"
                                                        dataList={STATUS_SETTING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Fill-Rate")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={fillRateSearch || null}
                                                        onChangeSelect={(e: any) => setFillRateSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Setting'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Click Option')} isShow={true}>
                                                    <DropdownOption
                                                        name="flg_click"
                                                        dataList={STATUS_SETTING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Click Option")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={flgClickSearch || null}
                                                        onChangeSelect={(e: any) => setFlgClickSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Setting'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Size Option')} isShow={true}>
                                                    <DropdownOption
                                                        name="flg_banner_size"
                                                        dataList={STATUS_SETTING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Size Option")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={flgBannerSizeSearch || null}
                                                        onChangeSelect={(e: any) => setFlgBannerSizeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Setting'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Block Referral')} isShow={true}>
                                                    <DropdownOption
                                                        name="is_block_referral"
                                                        dataList={STATUS_SETTING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Block Referral")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={isBlockReferralSearch || null}
                                                        onChangeSelect={(e: any) => setIsBlockReferralSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Setting'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2 mb-2'>
                                                <LabelWrapper label={t('Block Keyword')} isShow={true}>
                                                    <DropdownOption
                                                        name="is_except_keyword"
                                                        dataList={STATUS_SETTING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Block Keyword")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={isExceptKeywordSearch || null}
                                                        onChangeSelect={(e: any) => setIsExceptKeywordSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Setting'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={12} lg={12} xl={12} xxl={2} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2 mb-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isInventoryLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableInventory
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed sticky-table-inventory"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={inventories?.list?.length ? inventories?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={inventories?.pagination?.total}
                                            customPageCount={Math.ceil(Number(inventories?.pagination?.total) / Number(inventories?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isInventoryLoading}
                                            handleCreateSubInventoryFrom={handleCreateSubInventoryFrom}
                                            handleShowConfirmUpdateConfig={handleShowConfirmUpdateConfig}
                                            setItemUpdateMemo={setItemUpdateMemo}
                                            setInventoryDetail={setInventoryDetail}
                                            handleConfirmDelete={handleConfirmDelete}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {(windowSize?.width >= MAX_WIDTH_SCREEN) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(inventoryDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-600'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setInventoryDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!inventoryDetail?.id ? (inventoryDetail?.is_preview ? t('Button Detail Inventory') : t('Button Update Inventory')) : t('Button Create Inventory')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormInventory medias={listMedia} inventories={listInventory} detail={inventoryDetail} triggerRefresh={handleRefresh} handleClose={() => setInventoryDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={inventoryDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setInventoryDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setInventoryDetail((_prev) => null)}>
                                    {!!inventoryDetail?.id ? (inventoryDetail?.is_preview ? t('Button Detail Inventory') : t('Button Update Inventory')) : t('Button Create Inventory')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormInventory medias={listMedia} inventories={listInventory} detail={inventoryDetail} triggerRefresh={handleRefresh} handleClose={() => setInventoryDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <Modal
                isOpen={itemUpdateMemo !== null}
                centered={true}
                size="md"
                toggle={() => setItemUpdateMemo((_prev) => null)}
                keyboard={true}
            >
                <ModalHeader toggle={() => setItemUpdateMemo((_prev) => null)}>
                    {t('Button Update Memo')}
                </ModalHeader>
                <ModalBody className="">
                    <Col md={12} lg={12}>
                        <div>
                            <label className="form-label">
                                {t("Memo")}
                                <span className="text-danger"> *</span>
                            </label>
                            <Input
                                type="textarea"
                                rows={3}
                                id="memo"
                                name="memo"
                                autocomplete={false}
                                value={itemUpdateMemo?.memo}
                                placeholder={`${t("Memo")}...`}
                                onChange={(event: any) => {
                                    setItemUpdateMemo((prev: any) => ({ ...prev, memo: event?.target?.value || '' }))
                                }}
                            />
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                            <button
                                className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                color="light"
                                type="button"
                                disabled={isLoadingUpdateMemo}
                                style={{ width: '100px' }}
                                onClick={() => setItemUpdateMemo((_prev) => null)}
                            >
                                {t("Button Close")}
                            </button>
                            <button
                                className="btn btn-primary rounded-pill fs-14"
                                color="success"
                                type="button"
                                disabled={isLoadingUpdateMemo}
                                style={{ width: '150px' }}
                                onClick={() => handleUpdateMemo()}
                            >
                                {isLoadingUpdateMemo ? (
                                    <Spinner size="sm" className="me-2"></Spinner>
                                ) :
                                    <> {t("Button Update")}</>}
                            </button>
                        </div>
                    </Col>
                </ModalBody>
            </Modal>
            <ModalConfirm
                header={t('Button Delete Inventory')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
            <ModalConfirm
                textButtonConfirm={t('Button Update')}
                classButtonConfirm='btn-primary'
                classIconButtonConfirm=''
                header={(itemUpdate?.data?.flg_banner_size || itemUpdate?.data?.flg_click || itemUpdate?.data?.fill_rate || itemUpdate?.data?.is_block_referral || itemUpdate?.data?.is_except_keyword) ? t('Confirm to ON') : t('Confirm to OFF')}
                title={t('Are you sure you want to update this?')}
                content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
                isShowIcon={false}
                isOpen={!!itemUpdate}
                isLoading={isLoadingUpdate}
                onClose={() => setItemUpdate((prev: any) => null)}
                onConfirm={handleOnConfirmUpdate}
            />
        </React.Fragment>
    );
};

export default ManagementInventory;