import { createSlice } from "@reduxjs/toolkit";
import { IInventory } from "api/types/_inventory";
import { IHistoryConfigCPM, IMedia } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getAgencies, getCredentials, getHistoryConfigCPM, getInventories, getMedias } from "./thunk";
import { ICredential } from "api/types/_credential";
import { IAgency } from "api/types/_agency";


export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  inventories: ResponseData<IInventory[]> & PaginationResponse | null,
  isInventoryLoading: boolean,
  isInventorySuccess: boolean,

  historyConfigs: ResponseData<IHistoryConfigCPM[]> & PaginationResponse | null,
  isHistoryConfigLoading: boolean,
  isHistoryConfigSuccess: boolean,

  credentials: ResponseData<ICredential[]> & PaginationResponse | null,
  isCredentialLoading: boolean,
  isCredentialSuccess: boolean,

  agencies: ResponseData<IAgency[]> & PaginationResponse | null,
  isAgencyLoading: boolean,
  isAgencySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  inventories: null,
  isInventoryLoading: false,
  isInventorySuccess: false,

  historyConfigs: null,
  isHistoryConfigLoading: false,
  isHistoryConfigSuccess: false,

  credentials: null,
  isCredentialLoading: false,
  isCredentialSuccess: false,

  agencies: null,
  isAgencyLoading: false,
  isAgencySuccess: false,

  error: {},
};

const ManagementSlice = createSlice({
  name: "Management",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //get Inventories
    builder.addCase(getInventories.pending, (state: IState, action: any) => {
      state.isInventoryLoading = true
    });
    builder.addCase(getInventories.fulfilled, (state: IState, action: any) => {
      state.inventories = action.payload.data;
      state.isInventorySuccess = true;
      state.isInventoryLoading = false;
    });
    builder.addCase(getInventories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventorySuccess = false;
      state.isInventoryLoading = false;
    });


    //get history configs
    builder.addCase(getHistoryConfigCPM.pending, (state: IState, action: any) => {
      state.isHistoryConfigLoading = true
    });
    builder.addCase(getHistoryConfigCPM.fulfilled, (state: IState, action: any) => {
      state.historyConfigs = action.payload.data;
      state.isHistoryConfigSuccess = true;
      state.isHistoryConfigLoading = false;
    });
    builder.addCase(getHistoryConfigCPM.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHistoryConfigSuccess = false;
      state.isHistoryConfigLoading = false;
    });


    //get Credentials
    builder.addCase(getCredentials.pending, (state: IState, action: any) => {
      state.isCredentialLoading = true
    });
    builder.addCase(getCredentials.fulfilled, (state: IState, action: any) => {
      state.credentials = action.payload.data;
      state.isCredentialSuccess = true;
      state.isCredentialLoading = false;
    });
    builder.addCase(getCredentials.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCredentialSuccess = false;
      state.isCredentialLoading = false;
    });


    //get Agencies
    builder.addCase(getAgencies.pending, (state: IState, action: any) => {
      state.isAgencyLoading = true
    });
    builder.addCase(getAgencies.fulfilled, (state: IState, action: any) => {
      state.agencies = action.payload.data;
      state.isAgencySuccess = true;
      state.isAgencyLoading = false;
    });
    builder.addCase(getAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAgencySuccess = false;
      state.isAgencyLoading = false;
    });

  },
});

export default ManagementSlice.reducer;