import { IAgency } from "api/types/_agency";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import ModalConfirm from "components/Common/ModalConfirm";
import SVGImageDefault from "components/Images/SVGImageDefault";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_MEDIA_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { getGenerateApiKeyMedia, postMedia, putMedia } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IMedia | null;
    agencies?: IAgency[]
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormMedia = ({
    isModal = false,
    id,
    detail,
    agencies = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [keyShow, setKeyShow] = useState<boolean>(false);

    const [idConfirmRefreshKey, setIdConfirmRefreshKey] = useState<number | null>(null);
    const [isConfirmRefreshKeyLoading, setIsConfirmRefreshKeyLoading] = useState<boolean>(false);

    const AGENCY_OPTIONS = (agencies?.map((item) => ({ label: item?.name, value: String(item?.code) })) || []) as Option[];
    const STATUS_MEDIA_OPTIONS_LANG = STATUS_MEDIA_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,
                logo_url: values?.logo_url,
                website: values?.website,
                contact_name: values?.contact_name,
                contact_phone: values?.contact_phone,
                contact_email: values?.contact_email,
                ...(values?.agency?.value ? { agency_code: values?.agency?.value ? Number(values?.agency?.value) : '' } : {}),
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_MEDIA_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putMedia(detail?.id, data) : await postMedia(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        // code: Yup.number().required(`${t("This is required")}`).typeError(`${t("This must type number")}`),
        name: Yup.string().required(`${t("This is required")}`),
        logo_url: Yup.string().required(`${t("This is required")}`),
        website: Yup.string().required(`${t("This is required")}`),
        contact_name: Yup.string().nullable(),
        contact_phone: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).nullable(),
        contact_email: Yup.string().email(`${t('Please enter a valid email address')}`).nullable(),
        agency: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            // code: '',
            name: '',
            logo_url: '',
            website: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
            agency: null,
            api_key: '',
            api_secret: '',
            is_active: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        // const v_code = valueDefault?.code;
        const v_name = valueDefault?.name;
        const v_logo_url = valueDefault?.logo_url;
        const v_website = valueDefault?.website;
        const v_contact_name = valueDefault?.contact_name;
        const v_contact_phone = valueDefault?.contact_phone;
        const v_contact_email = valueDefault?.contact_email;
        const v_agency = AGENCY_OPTIONS?.find((item) => (String(item?.value) === String(valueDefault?.agency?.agency_code))) || null;
        const v_api_key = valueDefault?.api_key || '';
        const v_api_secret = valueDefault?.api_secret || '';
        const v_is_active = STATUS_MEDIA_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        // formik.setFieldValue("code", v_code);
        formik.setFieldValue("name", v_name);
        formik.setFieldValue("logo_url", v_logo_url);
        formik.setFieldValue("website", v_website);
        formik.setFieldValue("contact_name", v_contact_name);
        formik.setFieldValue("contact_phone", v_contact_phone);
        formik.setFieldValue("contact_email", v_contact_email);
        formik.setFieldValue("agency", v_agency);
        formik.setFieldValue("is_active", v_is_active);
        formik.setFieldValue("api_key", v_api_key);
        formik.setFieldValue("api_secret", v_api_secret);

    };


    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);


    const onCloseConfirmRefreshKeyClick = () => {
        setIdConfirmRefreshKey((_prev) => null);
    };

    const handleActionRefreshKey = async () => {
        if (!idConfirmRefreshKey) {
            return;
        }
        try {
            setIsConfirmRefreshKeyLoading((_prev) => true);
            const response: any = await getGenerateApiKeyMedia(idConfirmRefreshKey);
            if (response?.data) {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                setIdConfirmRefreshKey((_prev) => null);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                formik.setFieldValue("api_key", response?.data?.api_key);
                formik.setFieldValue("api_secret", response?.data?.api_secret);
                triggerRefresh && triggerRefresh(false);
            } else {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmRefreshKeyLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };
    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand("copy", true, text);
        }
        toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
    };

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Row className="mt-2 pe-0">
                                                <Col md={4} lg={4} className="pe-0">
                                                    {!!formik?.values?.logo_url ? <img className="avatar-md rounded object-fit-contain" loading="lazy" style={{ height: '72px', background: '#eaeaea' }} alt={formik?.values?.name} src={formik?.values?.logo_url} /> : <SVGImageDefault style={{ height: '72px' }} />}
                                                </Col>
                                                {/* <Col lg={8} className="px-0">
                                                    <Row>
                                                        <Col lg={12} className="pe-0">
                                                            <div>
                                                                <label className="form-label pt-1">
                                                                    {t("Code")}
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="code"
                                                                    name="code"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.code}
                                                                    disabled={!!detail?.id}
                                                                    placeholder={`${t("Code")}...`}
                                                                    onChange={(event: any) => formik.setFieldValue("code", event?.target?.value || "")}
                                                                />
                                                                {formik.touched.code && formik.errors.code ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.code}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Col> */}
                                                <Col md={8} lg={8} className="ps-2 pe-0 ps-md-0 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Status")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownStatus
                                                            name="is_active"
                                                            dataList={STATUS_MEDIA_OPTIONS_LANG || []}
                                                            placeholder={`${t("Status")}...`}
                                                            className="dropdown-status-rounded mt-1"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.is_active ||
                                                                STATUS_MEDIA_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("is_active", event)
                                                            }
                                                            colors={["danger", "success"]}
                                                        />
                                                        {formik.touched.is_active && formik.errors.is_active ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.is_active}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Media Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Logo URL")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="logo_url"
                                                        name="logo_url"
                                                        autocomplete={false}
                                                        value={formik?.values?.logo_url}
                                                        placeholder={`${t("Logo URL")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("logo_url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.logo_url && formik.errors.logo_url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.logo_url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Domain")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="website"
                                                        name="website"
                                                        autocomplete={false}
                                                        value={formik?.values?.website}
                                                        placeholder={`${t("Domain")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("website", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.website && formik.errors.website ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.website}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Agency")}
                                                    </label>
                                                    <DropdownOption
                                                        name="agency"
                                                        dataList={AGENCY_OPTIONS || []}
                                                        placeholder={`${t("Agency")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.agency}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("agency", event)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.agency && formik.errors.agency ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.agency}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {!!detail?.is_preview && (<>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Media API Key")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_key"
                                                                name="api_key"
                                                                disabled={true}
                                                                type="text"
                                                                value={formik?.values?.api_key}
                                                                placeholder={`${t("Media API Key")}...`}
                                                            />
                                                        </div>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_key || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Media API Secret")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative auth-pass-inputgroup w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_secret"
                                                                name="api_secret"
                                                                disabled={true}
                                                                type={keyShow ? "text" : "password"}
                                                                value={formik?.values?.api_secret}
                                                                placeholder={`${t("Media API Secret")}...`}
                                                            />
                                                            <button
                                                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                                onClick={() => setKeyShow(!keyShow)}
                                                                type="button"
                                                                id="password-addon"

                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>

                                                        <button
                                                            className="btn btn-sm btn-soft-primary ms-2 fs-14"
                                                            onClick={() =>
                                                                setIdConfirmRefreshKey((prev) => Number(detail?.id))
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-refresh-line align-middle"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_secret || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </>)}
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Name")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_name}
                                                        placeholder={`${t("Contact Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_name && formik.errors.contact_name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Phone")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_phone}
                                                        placeholder={`${t("Contact Phone")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_phone", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_phone && formik.errors.contact_phone ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_phone}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Email")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_email}
                                                        placeholder={`${t("Contact Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_email && formik.errors.contact_email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '130px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Media") : t("Button Create Media")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
            <ModalConfirm
                textButtonConfirm={t("Button Refresh Key Media")}
                classButtonConfirm="btn-soft-primary"
                classIconButtonConfirm="ri-refresh-line"
                header={t("Button Refresh Key Media")}
                title={t("Do you want to refresh key of media!")}
                content={t(
                    "After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action."
                )}
                isShowIcon={false}
                isOpen={!!idConfirmRefreshKey}
                isLoading={isConfirmRefreshKeyLoading}
                onClose={onCloseConfirmRefreshKeyClick}
                onConfirm={handleActionRefreshKey}
            />

        </React.Fragment >
    );
};

export default FormMedia;
