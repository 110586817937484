import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticApi from "api/statisticApi";
import { formatQueryParams } from "helpers/format";
import { changeStatusBlockIPAction, changeStatusBlockUUIDAction } from "./reducer";

export const ipStatistics = createAsyncThunk("IP Statistics" , async (params: any = {}) => {
  try{
    const response = await statisticApi.ipStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const uuidStatistics = createAsyncThunk("UUID Statistics" , async (params: any = {}) => {
  try{
    const response = await statisticApi.uuidStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const uuidBlocks = createAsyncThunk("UUID Blocks" , async (params: any = {}) => {
  try{
    const response = await statisticApi.uuidBlocks(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const uuidBlocksAds = createAsyncThunk("UUID Blocks Ads" , async (params: any = {}) => {
  try{
    const response = await statisticApi.uuidBlocksAds(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const postLookupIP = async (params: any = {}) => {
  try{
    const response = await statisticApi.postLookupIP(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const putStatusBlockIP = async (ip: string, data: any = {}) => {
  try{
    const response = await statisticApi.putStatusBlockIP(ip, data)
    return response;
  }catch (error) {
    return error;
  }
};

export const changeStatusBlockIP = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusBlockIPAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};

export const changeStatusBlockUUID = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusBlockUUIDAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};

export const blockMultiplesUUID = async (params: any = {}) => {
  try{
    const response = await statisticApi.postBlockMultiplesUUID(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteBlockMultiplesUUID = async (params: any = {}) => {
  try{
    const response = await statisticApi.deleteBlockMultiplesUUID(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const blockAdsMultiplesUUID = async (params: any = {}) => {
  try{
    const response = await statisticApi.postBlockAdsMultiplesUUID(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteBlockAdsMultiplesUUID = async (params: any = {}) => {
  try{
    const response = await statisticApi.deleteBlockAdsMultiplesUUID(params)
    return response;
  }catch (error) {
    return error;
  }
};